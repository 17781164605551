import CrudController from './crud_controller'

export default class extends CrudController {
  static targets = [ "id" ]


  createProcessHomepage(event) {
    this.create(event, 'ProcessHomepage#create')
  }

  getId(event){
    document.getElementById('process-homepage-id').value = event.currentTarget.id
  }

  deleteProcessHomepage(event){
    this.destroy(event, 'ProcessHomepageReflex#destroy', this.id)
  }

  editProcessHomepage(event){
    this.edit(event, 'ProcessHomepageReflex#edit')
  }

  updateProcessHomepage(event){
    this.update(event, 'ProcessHomepageReflex#update')
  }

  reset(event){
    event.preventDefault()
    Turbolinks.visit(location.href)
  }

  get id() {
    return this.idTarget.value
  }
}
