import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["id"]

  createAdvisory(event) {
    event.preventDefault();
    const form = event.currentTarget;
    this.stimulate("Advisory#create", form)
    Turbolinks.visit(location.href)
  }

  getId(event){
    document.getElementById('advisory-id').value = event.currentTarget.id
  }

  deleteAdvisory(event){
    event.preventDefault();
    event.stopPropagation();
    this.stimulate('Advisory#destroy', this.id)
    Turbolinks.visit(location.href)
  }

  editAdvisory(event){
    event.preventDefault();
    this.stimulate('Advisory#edit', event.currentTarget.id)
  }

  updateAdvisory(event){
    this.stimulate('Advisory#update', event.currentTarget)
    Turbolinks.visit(location.href)
  }

  reset(event){
    event.preventDefault()
    Turbolinks.visit(location.href)
  }

  get id() {
    return this.idTarget.value
  }
}
