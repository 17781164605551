document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({
    trigger : 'hover',
  });
  $('.download-information').popover({
    container: 'body'
  })
  
})
// for Trix target blank
$(document).ready(function() {
  $(".trix-content a").click(function(e) {
      $(this).attr("target","_blank");
  });
});