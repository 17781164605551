import CrudController from './crud_controller'

export default class extends CrudController {
  static targets = [ "id" ]

  clickUnsure(event){
    let commercial = document.getElementById("commercial-link")
    let non_commercial = document.getElementById("non-commercial-link")
    event.currentTarget.checked ? this.checkedUnsure(commercial, non_commercial)
      : this.unCheckedUnsure(commercial, non_commercial)
  }

  unCheckedUnsure(commercial, non_commercial){
    this.hideShowUnsure("commercial-button", "commercial-link")
    document.getElementById("unsure-text").classList.add('d-none')
    commercial.removeAttribute("href")
    non_commercial.removeAttribute("href")
    commercial.setAttribute('href', '/leads?regtype=1&regunsure=0')
    non_commercial.setAttribute('href', '/leads?regtype=2&regunsure=0')
  }

  checkedUnsure(commercial, non_commercial){
    this.hideShowUnsure("commercial-link", "commercial-button")
    document.getElementById("unsure-text").classList.remove('d-none')
    commercial.removeAttribute("href")
    non_commercial.removeAttribute("href")
    commercial.setAttribute('href', '/leads?regtype=1&regunsure=1')
    non_commercial.setAttribute('href', '/leads?regtype=2&regunsure=1')
  }

  hideShowUnsure(hide_element, show_element) {
    document.getElementById(show_element).classList.remove('d-none')
    document.getElementById(hide_element).classList.add('d-none')
  }

  createHome(event) {
    this.create(event, 'Home#create')
  }

  getId(event){
    document.getElementById('home-id').value = event.currentTarget.id
  }

  deleteHome(event){
    this.destroy(event, 'Home#destroy', this.id)
  }

  editHome(event){
    this.edit(event, 'Home#edit')
  }

  updateHome(event){
    this.update(event, 'Home#update')
  }

  reset(event){
    event.preventDefault()
    Turbolinks.visit(location.href)
  }

  get id() {
    return this.idTarget.value
  }
}
