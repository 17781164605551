import MethodController from './method_controller'

export default class extends MethodController {

    // Selecting language method 
    selectingLanguage(event, selectionUl, selectionGroup, selectedCountry, selectedId, selectedClass){
      const get_id = event.currentTarget.id
      const result = get_id.split('-')
      const get_ul = selectionUl + result[3]
      
      const selection = selectionGroup + result[3]    
      const selected_country = selectedCountry + result[3]
      const selected_language = result[0] + selectedId + result[3]
      
      this.selected(selected_country, selected_language)
      this.unselected(get_id) 
      this.get_selected(get_ul, selection)
      
      document.getElementById(get_id).classList.add("selected_language")
      document.getElementById(selected_language).classList.add(selectedClass)
    }
  
    // Selecting language method 
    unSelectingLanguage(event, seledtedUl, selectionGroup, selectedCountry, selectedId, selectedClass){
      const get_id = event.currentTarget.id
      const result = get_id.split('-')
      const get_ul = seledtedUl + result[2]
  
      const selection = selectionGroup + result[2]
      const selected_country = selectedCountry + result[2]
      const selected_language = result[0] + selectedId + result[2]
  
      this.selected(selection, selected_language)
      this.unselected(get_id)
      this.get_selected(get_ul, selected_country)
       
      document.getElementById(get_id).classList.remove(selectedClass)
      document.getElementById(selected_language).classList.remove("selected_language")  
    }
  
    selected(country, language){
      const selected_country = document.getElementById(country)
      const selected_language = document.getElementById(language)
  
      selected_country.style.display = "block"
      selected_language.style.display = "block"
    }
  
    unselected(language){
      const selected_language = document.getElementById(language)
      selected_language.style.display = "none"
  
    }
  
    get_selected(ul_id, selection){
      const hide_selected_country = document.getElementById(selection)
  
      let all_hidden =  true
      let navbar = document.getElementById(ul_id).querySelectorAll('li');
      navbar.forEach((item, index) => {
        if (index != 0) {
          if (item.style.display != "none") {
            all_hidden = false
            return false
          }
        }
      });
  
    let hide_country_selected =  all_hidden ? hide_selected_country.style.display = "none" : hide_selected_country.style.display = "block"
      return hide_country_selected
    }
}
