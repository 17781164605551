import MethodController from './method_controller'

export default class extends MethodController {

	clickCheckAvailableVersion(event){
		const current_value = event.currentTarget.value
		const selected_level_ids = this.getCurrentVersion('available-ids-', event)
		const selected_product_value = this.getCurrentVersion('available-versions-', event)
		const products_id = this.getCurrentVersion('product-id-', event)
		const products = this.getCurrentVersion('requested-version-', event)
		const unrequested_versions = this.getCurrentVersion('unrequested-version-', event)
		const checkbox = event.currentTarget.checked

		this.checkDigital()
		checkbox ?  this.insertValueInAvailableVersion(products_id, products, unrequested_versions, selected_level_ids, selected_product_value, current_value) 
			: this.removeValueInAvailableVersion(products_id, products, unrequested_versions, selected_level_ids, selected_product_value, current_value) 
		
	}

	clickCheckUnavailableVersion(event){
		const current_value = event.currentTarget.value
		const checkbox = event.currentTarget.checked

		this.checkDigital()
		this.checkUnavailable()
		checkbox ?  this.insertRequestedUnavailableVersion(event, current_value) : this.removeRequestedUnavailableVersion(event, current_value)
	}

	insertRequestedUnavailableVersion(event, value){
		const unrequestedUnavailVersion = this.getCurrentVersion('unrequested-unavail-version-', event)
		const requestedUnavailVersion = this.getCurrentVersion('requested-unavail-version-', event)
		let select_requested_unavailable_version = this.getNewLineArray(unrequestedUnavailVersion)
		select_requested_unavailable_version = select_requested_unavailable_version.filter(v => v !== value)
		
		this.insertValueinArray(requestedUnavailVersion, value)
		this.newValue(unrequestedUnavailVersion, select_requested_unavailable_version) 
	}

	removeRequestedUnavailableVersion(event, value){
		const unrequestedUnavailVersion = this.getCurrentVersion('unrequested-unavail-version-', event)
		const requestedUnavailVersion = this.getCurrentVersion('requested-unavail-version-', event)

		let unselect_requested_unavailable_version = this.getNewLineArray(unrequestedUnavailVersion)
		let select_requested_unavailable_version = this.getNewLineArray(requestedUnavailVersion)
		select_requested_unavailable_version = select_requested_unavailable_version.filter(v => v !== value)
		unselect_requested_unavailable_version.push(value)
		this.newValue(requestedUnavailVersion, select_requested_unavailable_version)
		this.newValue(unrequestedUnavailVersion, unselect_requested_unavailable_version)  
	}

	removeValueInAvailableVersion(products_id, products, unrequested_versions, selected_level_ids, selected_product_value, value){
		const available_version =  this.getArray(selected_product_value) 
		const availabe_ids = this.getArray(selected_level_ids)
		const value_index = available_version.indexOf(value)
		const selected_requested_version = this.getNewLineArray(products)
		const selected_requested_product_id = this.getNewLineArray(products_id)

		const remove_version = selected_requested_version.filter(version => version !== value)
		const remove_product_id = selected_requested_product_id.filter(ai => ai !== availabe_ids[value_index])
		this.insertValueinArray(unrequested_versions, value) 

		this.newValue(products, remove_version)
		this.newValue(products_id, remove_product_id)
	}

	insertValueInAvailableVersion(products_id, products, unrequested_versions, selected_level_ids, selected_product_value, value){
		const available_version =  this.getArray(selected_product_value) 
		const availabe_ids = this.getArray(selected_level_ids)
		const value_index = available_version.indexOf(value)
		const selected_requested_version = this.getArray(products)
		const selected_requested_version_id = this.getArray(products_id)
		selected_requested_version.splice(value_index, 0, value)
		selected_requested_version_id.splice(value_index, 0, availabe_ids[value_index])
		const insert_value_id = selected_requested_version_id.filter(id => id == id)
		const insert_value_version = selected_requested_version.filter(v => v == v)

		this.removeValueInArray(unrequested_versions, value) 

		this.newValue(products_id, insert_value_id)
		this.newValue(products, insert_value_version)
	}
	
	removeValueInArray(id, value){
		const array = this.getNewLineArray(id)
		const remove_array = array.filter(m => m !== value)

		this.newValue(id, remove_array)
	}

	insertValueinArray(id, value){
		let unrequested_available_version = document.getElementById(id)
			unrequested_available_version.value == "" ? unrequested_available_version.value = value 
				: unrequested_available_version.value = unrequested_available_version.value + '\n' + value
	}

	newValue(id, ArrayValue){
		const new_array = ArrayValue.join('\n')

		let findHiddenField = document.getElementById(id)
			findHiddenField.value = new_array
	}

	getNewLineArray(id){
		return document.getElementById(id).value.split('\n')
	}

	getArray(id){
		return document.getElementById(id).value.split(',')
	}
	
	getCurrentVersion(id, event){
		return id + event.currentTarget.name
	}

}


