import CrudController from './crud_controller'

export default class extends CrudController {

  update(event){
    event.currentTarget.checked ? this.active(event) : this.inActive(event)
  }

  active(event){
    let confirmation = confirm('Are you sure you want to active this product')
    confirmation ? this.updateStatus(event, true) : event.currentTarget.checked = false
  }

  inActive(event){
    let confirmation = confirm('Are you sure you want to inactive this product')
    confirmation ? this.updateStatus(event, false) : event.currentTarget.checked = true
  }

  updateStatus(event, status){
      event.currentTarget.checked = status
      this.stimulate('Product#update', event.currentTarget)
  }
}
