import CrudController from './crud_controller'

export default class extends CrudController {
  static targets = [ "id" ]


  createLevel(event) {
    this.create(event, 'Level#create')
  }

  getId(event){
    document.getElementById('level-id').value = event.currentTarget.id
  }

  deleteLevel(event){
    this.destroy(event, 'LevelReflex#destroy', this.id)
  }

  editLevel(event){
    this.edit(event, 'Level#edit')
  }

  updateLevel(event){
    this.update(event, 'Level#update')
  }

  get id() {
    return this.idTarget.value
  }


}
