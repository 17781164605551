import MethodController from './method_controller'

export default class extends MethodController {

  clickPrev(e) {
    this.executeNextTab("mytab-link-6", "mystep-6", "mytab-link-7")
    this.getSelected('current_tab', '6th')
  }

  changeDate(e) {
    let dateValue = this.getElement("00N1r00000HHVA3").value
    dateValue.trim() != "" ? this.getElement("mystep-7").classList.add('valid') : this.getElement("mystep-7").classList.remove('valid')
  }

  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)
    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    this.getElement(currentTab).classList.remove('text-bold')

    find_tab.click()
  }

}
