import SelectingMethodController from './selecting_method_controller'
import { Modal } from 'bootstrap'

export default class extends SelectingMethodController {

  clickNext(e) {
    let isValid = true
    isValid = this.validateFields(3)
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideOrShowPlatform()
      if (this.isChekedElement("Level_eq-5d-3l")) {
        this.executeNextTab("mytab-link-3-3l", "mystep-3", "mytab-link-3")
        this.getSelected('current_tab', '3.1')
        this.showHideElement("next-tab-3-3l", "eq5d3l-btn-accordion1",
          "eq5d3l-btn-collapse", "prev-tab-3l", 'eq5d3lcollapseOne', 'prev-tab-3-3l')

      } else if (this.isChekedElement("Level_eq-5d-5l")) {
        this.executeNextTab("mytab-link-3-5l", "mystep-3", "mytab-link-3")
        this.getSelected('current_tab', '3.2')
        this.showHideElement("next-tab-3-5l", "eq5d5l-btn-accordion1",
          "eq5d5l-btn-collapse", "prev-tab-5l", "eq5d5lcollapseOne", 'prev-tab-3-5l')
      } else if (this.isChekedElement("Level_eq-5d-y-3l")) {
        this.executeNextTab("mytab-link-3-y", "mystep-3", "mytab-link-3")
        this.getSelected('current_tab', '3.3')
        this.showHideElement("next-tab-3-y", "eq5dy-btn-accordion1",
          "eq5dY-btn-collapse", 'prev-tab-y', 'eq5dycollapseOne', 'prev-tab-3-y')
      } else if (this.isChekedElement("Level_eq-5d-y-5l")) {
        this.executeNextTab("mytab-link-3-y-5l", "mystep-3", "mytab-link-3")
        this.getSelected('current_tab', '3.4')
        this.showHideElement("next-tab-3-y-5l", "eq5dy5l-btn-accordion1",
          "eq5dY5l-btn-collapse", 'prev-tab-y-5l', 'eq5dy5lcollapseOne', 'prev-tab-3-y-5l')
      } else {
        this.executeNextTab("mytab-link-4", "mystep-4", "mytab-link-3")
        this.getSelected('current_tab', '4')
      }
    }
  }

  showHideElement(btn_one, btn_two, card_one, prev_btn_two, collaspe_one, prev_btn_one) {
    this.getElement(card_one).click()
    this.getElement(collaspe_one).classList.add('show')
    this.hideElement(btn_one)
    this.showElement(btn_two)
    this.showHidePrevBtn(prev_btn_one, prev_btn_two)

  }

  clickNext3L(e) {
    let isValid = true
    isValid = this.validateFields(3.1)
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideOrShowPlatform()
      this.showElement("eq5d3l_check")
      this.showHidePrevBtn('prev-tab-3l', 'prev-tab-3-3l')

      if (this.isChekedElement("Level_eq-5d-5l")) {
        this.executeNextTab("mytab-link-3-5l", "mystep-3", "mytab-link-3-3l")
        this.getSelected('current_tab', '3.2')
        this.showHideElement("next-tab-3-5l", "eq5d5l-btn-accordion1",
          "eq5d5l-btn-collapse", "prev-tab-5l", "eq5d5lcollapseOne", 'prev-tab-3-5l')
      } else if (this.isChekedElement("Level_eq-5d-y-3l")) {
        this.executeNextTab("mytab-link-3-y", "mystep-3", "mytab-link-3-5l")
        this.getSelected('current_tab', '3.3')
        this.showHideElement("next-tab-3-y", "eq5dy-btn-accordion1",
          "eq5dY-btn-collapse", 'prev-tab-y', 'eq5dycollapseOne', 'prev-tab-3-y')
      } else if (this.isChekedElement("Level_eq-5d-y-5l")) {
        this.executeNextTab("mytab-link-3-y-5l", "mystep-3", "mytab-link-3-y")
        this.getSelected('current_tab', '3.4')
        this.showHideElement("next-tab-3-y-5l", "eq5dy5l-btn-accordion1",
          "eq5dY5l-btn-collapse", 'prev-tab-y-5l', 'eq5dy5lcollapseOne', 'prev-tab-3-y-5l')
      } else {
        this.executeNextTab("mytab-link-4", "mystep-4", "mytab-link-3-3l")
        this.getSelected('current_tab', '4')
      }
    }
  }

  clickNext5L(e) {
    let isValid = true
    isValid = this.validateFields(3.2)
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideOrShowPlatform()
      this.showElement("eq5d5l_check")
      this.getElement("prev-tab-3-5l").style.display = "block"

      if (this.isChekedElement("Level_eq-5d-y-3l")) {
        this.executeNextTab("mytab-link-3-y", "mystep-3", "mytab-link-3-5l")
        this.getSelected('current_tab', '3.3')
        this.showHideElement("next-tab-3-y", "eq5dy-btn-accordion1",
          "eq5dY-btn-collapse", 'prev-tab-y', 'eq5dycollapseOne', 'prev-tab-3-y')
      } else if (this.isChekedElement("Level_eq-5d-y-5l")) {
        this.executeNextTab("mytab-link-3-y-5l", "mystep-3", "mytab-link-3-y")
        this.getSelected('current_tab', '3.4')
        this.showHideElement("next-tab-3-y-5l", "eq5dy5l-btn-accordion1",
          "eq5dY5l-btn-collapse", 'prev-tab-y-5l', 'eq5dy5lcollapseOne', 'prev-tab-3-y-5l')
      } else {
        this.executeNextTab("mytab-link-4", "mystep-4", "mytab-link-3-5l")
        this.getSelected('current_tab', '4')
      }
    }
  }

  clickNextY(e) {
    let isValid = true
    isValid = this.validateFields(3.3)
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideOrShowPlatform()
      this.showElement("eq5dy_check")
      this.getElement("prev-tab-3-y").style.display = "block"

      if (this.isChekedElement("Level_eq-5d-y-5l")){
        this.executeNextTab("mytab-link-3-y-5l", "mystep-3", "mytab-link-3-y")
        this.getSelected('current_tab', '3.4')
        this.showHideElement("next-tab-3-y-5l", "eq5dy5l-btn-accordion1",
        "eq5dY5l-btn-collapse", 'prev-tab-y-5l', 'eq5dy5lcollapseOne', 'prev-tab-3-y-5l')
      } else {
      this.executeNextTab("mytab-link-4", "mystep-4", "mytab-link-3-y")
      this.getSelected('current_tab', '4')
      }
    }
  }

  clickNextY5L(e) {
    let isValid = true
    isValid = this.validateFields(3.4)
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideOrShowPlatform()
      this.showElement("eq5dy5l_check")
      this.getElement("prev-tab-3-y-5l").style.display = "block"
      this.executeNextTab("mytab-link-4", "mystep-4", "mytab-link-3-y-5l")
      this.getSelected('current_tab', '4')
    }
  }

  clickPrev(e) {
    this.executeNextTab("mytab-link-2", "mystep-2", "mytab-link-3")
    this.getSelected('current_tab', '2')
  }

  prevTab(prev_btn, next_btn, accordion_btn, btn_collpase, prev_btn_two, collapse_div) {
    this.showElement(prev_btn)
    this.showHideElement(next_btn, accordion_btn, btn_collpase, prev_btn_two, collapse_div, prev_btn)

  }

  clickPrev3LTab(e) {
    this.hideElement(e.currentTarget.id)
    this.prevTab('prev-tab-3-3l', "next-tab-3-3l", "eq5d3l-btn-accordion1",
      "eq5d3l-btn-collapse", "prev-tab-3l", 'eq5d3lcollapseOne')
  }

  clickPrev3L(e) {
    this.executeNextTab("mytab-link-3", "mystep-2", "mytab-link-3-3l")
    this.getSelected('current_tab', '3')
    this.hideModes3LPrev()
  }

  clickPrev5LTab(e) {
    this.hideElement(e.currentTarget.id)
    this.prevTab('prev-tab-3-5l', "next-tab-3-5l", "eq5d5l-btn-accordion1",
      "eq5d5l-btn-collapse", "prev-tab-5l", 'eq5d5lcollapseOne')

  }

  clickPrev5L(e) {
    if (this.isChekedElement("Level_eq-5d-3l")) {
      this.executeNextTab("mytab-link-3-3l", "mystep-3", "mytab-link-3-5l")
      this.getSelected('current_tab', '3.1')
    } else {
      this.executeNextTab("mytab-link-3", "mystep-2", "mytab-link-3-5l")
      this.getSelected('current_tab', '3')
    }
    this.hideModes5LPrev()
  }

  clickPrevYTab(e) {
    this.hideElement(e.currentTarget.id)
    this.prevTab('prev-tab-3-y', "next-tab-3-y", "eq5dy-btn-accordion1",
      "eq5dY-btn-collapse", "prev-tab-y", 'eq5dycollapseOne')
  }

  clickPrevY(e) {
    if (this.isChekedElement("Level_eq-5d-5l")) {
      this.executeNextTab("mytab-link-3-5l", "mystep-3", "mytab-link-3-y")
      this.getSelected('current_tab', '3.2')
    } else if (this.isChekedElement("Level_eq-5d-3l")) {
      this.executeNextTab("mytab-link-3-3l", "mystep-3", "mytab-link-3-y")
      this.getSelected('current_tab', '3.1')
    } else {
      this.executeNextTab("mytab-link-3", "mystep-2", "mytab-link-3-y")
      this.getSelected('current_tab', '3')
    }
    this.hideModesYPrev()
  }

  clickPrevY5LTab(e) {
    this.hideElement(e.currentTarget.id)
    this.prevTab('prev-tab-3-y-5l', "next-tab-3-y-5l", "eq5dy5l-btn-accordion1",
      "eq5dY5l-btn-collapse", "prev-tab-y-5l", 'eq5dy5lcollapseOne')
  }

  clickPrevY5L(e) {
    if (this.isChekedElement("Level_eq-5d-y-3l")) {
      this.executeNextTab("mytab-link-3-y", "mystep-3", "mytab-link-3-y-5l")
      this.getSelected('current_tab', '3.3')
    } else if (this.isChekedElement("Level_eq-5d-5l")) {
      this.executeNextTab("mytab-link-3-5l", "mystep-3", "mytab-link-3-y-5l")
      this.getSelected('current_tab', '3.2')
    } else if (this.isChekedElement("Level_eq-5d-3l")) {
      this.executeNextTab("mytab-link-3-3l", "mystep-3", "mytab-link-3-y-5l")
      this.getSelected('current_tab', '3.1')
    } else {
      this.executeNextTab("mytab-link-3", "mystep-2", "mytab-link-3-y-5l")
      this.getSelected('current_tab', '3')
    }
    this.hideModesYPrev()
  }

  clickVersion(e) {
    let ynotif = this.getElement("y-y3l-notif")
    // ternary operator
    if (this.getElement("regtype").value == '2') {
      if (e.target.value == "EQ-5D-3L" || e.target.value == "EQ-5D-5L") {
        this.checkedLevel3lAnd5l()
      }
    }

    this.isChekedElement("Level_eq-5d-3l") ? this.versionChecked("mytab-link-3-3l", "EQ-5D-3L", "version3l")
      : this.versionUnchecked("mytab-link-3-3l", "", "version3l", '.mode3l', '.platform3l', '.device3l', "eq5d3l_check", "EQ-5D-3L")

    this.isChekedElement("Level_eq-5d-5l") ? this.versionChecked("mytab-link-3-5l", "EQ-5D-5L", "version5l")
      : this.versionUnchecked("mytab-link-3-5l", "", "version5l", '.mode5l', '.platform5l', '.device5l', "eq5d5l_check", "EQ-5D-5L")

    if (this.isChekedElement("Level_eq-5d-y-3l")) {
      this.versionChecked("mytab-link-3-y", "EQ-5D-Y-3L", "versiony")
      ynotif.classList.remove('d-none')
    } else {
      this.versionUnchecked("mytab-link-3-y", "", "versiony", '.modey', '.platformy', '.devicey', "eq5dy_check", "EQ-5D-Y-3L")
      ynotif.classList.add('d-none')
    }

    if (this.isChekedElement("Level_eq-5d-y-5l")) {
      this.versionChecked("mytab-link-3-y-5l", "EQ-5D-Y-5L", "versiony5l")
    } else {
      this.versionUnchecked("mytab-link-3-y-5l", "", "versiony5l", '.modey5l', '.platformy5l', '.devicey5l', "eq5dy5l_check", "EQ-5D-Y-5L")
    }
  }

  versionChecked(myTabId, versionName, versionId) {
    this.editInnerHtmlElement(myTabId, versionName)
    this.getSelected(versionId, "yes")
  }

  checkedLevel3lAnd5l() {
    if (this.isChekedElement("Level_eq-5d-3l") && this.isChekedElement("Level_eq-5d-5l")) {
      let modalItem = new Modal(document.getElementById('adviseModal'), {
        backdrop: false
      })
      modalItem.show()
    }
  }

  versionUnchecked(myTabId, versionName, versionId, mode, platform, device, versionCheck, version) {
    this.editInnerHtmlElement(myTabId, versionName)
    this.getSelected(versionId, "no")
    this.findCheckedBoxes(mode, platform, device)
    this.hideElement(versionCheck)
    const version_matrix = this.getElement(version)
    if (version_matrix) {
      this.resetValue('requested-unavail-version-' + version)
      this.resetValue('unrequested-unavail-version-' + version)
      version_matrix.remove()
    }

  }

  findCheckedBoxes(mode, platform, device) {
    this.unCheckedBoxes(mode, 'yes')
    this.unCheckedBoxes(platform, '')
    this.unCheckedBoxes(device, '')
  }

  unCheckedBoxes(className, mode) {
    // ternary operator
    mode == 'yes' ? [...document.querySelectorAll(className)].map(e => { e.checked = false, this.changeBGcolor(e.id, "bg-white", "bgThird_y") })
      : [...document.querySelectorAll(className)].map(e => e.checked = false)
  }

  showHidePrevBtn(prev_one, prev_two) {
    this.showElement(prev_one)
    this.hideElement(prev_two)
    this.getElement(prev_two).style.display = "none"
    this.getElement(prev_one).style.display = "block"
  }

  eq5d3lClickNextAccordion1(e) {
    const mode_version = this.getCheckedValues('.mode3l:checked')
    let isValid = true
    this.getSelected("selected_mode3l", mode_version)
    if (mode_version == '') {
      alert('please select at least 1 EQ-5D-3L mode. From the questionaire "Who will complete the EQ-5D-3L?"')
      isValid = false
      this.showHidePrevBtn('prev-tab-3-3l', 'prev-tab-3l')
    }

    if (!isValid) {
      e.preventDefault()
    } else {
      this.showHidePrevBtn('prev-tab-3l', 'prev-tab-3-3l')

      this.hideElement(e.currentTarget.id)
      this.showElement("next-tab-3-3l")
      this.executeCollapse("eq5d3l-btn-collapse2", "eq5d3lCardTwo")
    }
  }

  eq5d3lClickDevice(e) {
    if (e.target.value == "BYOD") {
      if (e.target.checked) {
        this.checkElement("eq5d3l-Device_pdasmartphone")
        this.checkElement("eq5d3l-Device_tablet")
        this.checkElement("eq5d3l-Device_laptopdesktop")
        this.hideElement("eq5d3l-Device_pdasmartphone-div")
        this.hideElement("eq5d3l-Device_tablet-div")
        this.hideElement("eq5d3l-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5d3l-Device_pdasmartphone")
        this.uncheckElement("eq5d3l-Device_tablet")
        this.uncheckElement("eq5d3l-Device_laptopdesktop")
        this.showElement("eq5d3l-Device_pdasmartphone-div")
        this.showElement("eq5d3l-Device_tablet-div")
        this.showElement("eq5d3l-Device_laptopdesktop-div")
      }
    } else {
      if (this.isChekedElement("eq5d3l-Device_pdasmartphone") && this.isChekedElement("eq5d3l-Device_tablet") && this.isChekedElement("eq5d3l-Device_laptopdesktop")) {
        alert("Selecting All will be default to BYOD only selected")
        this.checkElement("eq5d3l-Device_byod")
        this.hideElement("eq5d3l-Device_pdasmartphone-div")
        this.hideElement("eq5d3l-Device_tablet-div")
        this.hideElement("eq5d3l-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5d3l-Device_byod")
        this.showElement("eq5d3l-Device_pdasmartphone-div")
        this.showElement("eq5d3l-Device_tablet-div")
        this.showElement("eq5d3l-Device_laptopdesktop-div")
      }
    }
    // this.isDeviceChecked()
  }

  eq5d3lClickPlatform(e) {
    if (e.target.checked) {
      this.getSelected("platform3LChecker", "yes")
      if (e.target.value == "REDCap") {
        this.uncheckElement("eq5d3l-Platform_qualtrics")
        this.uncheckElement("eq5d3l-Platform_limesurvey")
      } else if (e.target.value == "Qualtrics") {
        this.uncheckElement("eq5d3l-Platform_redcap")
        this.uncheckElement("eq5d3l-Platform_limesurvey")
      } else if (e.target.value == "LimeSurvey") {
        this.uncheckElement("eq5d3l-Platform_redcap")
        this.uncheckElement("eq5d3l-Platform_qualtrics")
      }
    } else {
      this.getSelected("platform3LChecker", "no")
    }
    this.isDigitalChecked()
  }

  eq5d5lClickPlatform(e) {
    if (e.target.checked) {
      this.getSelected("platform5LChecker", "yes")
      if (e.target.value == "REDCap") {
        this.uncheckElement("eq5d5l-Platform_qualtrics")
        this.uncheckElement("eq5d5l-Platform_limesurvey")
      } else if (e.target.value == "Qualtrics") {
        this.uncheckElement("eq5d5l-Platform_redcap")
        this.uncheckElement("eq5d5l-Platform_limesurvey")
      } else if (e.target.value == "LimeSurvey") {
        this.uncheckElement("eq5d5l-Platform_redcap")
        this.uncheckElement("eq5d5l-Platform_qualtrics")
      }
    } else {
      this.getSelected("platform5LChecker", "no")
    }
    this.isDigitalChecked()
  }

  eq5dyClickPlatform(e) {
    if (e.target.checked) {
      this.getSelected("platformYChecker", "yes")
      if (e.target.value == "REDCap") {
        this.uncheckElement("eq5dy-Platform_qualtrics")
        this.uncheckElement("eq5dy-Platform_limesurvey")
      } else if (e.target.value == "Qualtrics") {
        this.uncheckElement("eq5dy-Platform_redcap")
        this.uncheckElement("eq5dy-Platform_limesurvey")
      } else if (e.target.value == "LimeSurvey") {
        this.uncheckElement("eq5dy-Platform_redcap")
        this.uncheckElement("eq5dy-Platform_qualtrics")
      }
    } else {
      this.getSelected("platformYChecker", "no")
    }
    this.isDigitalChecked()
  }

  eq5dy5lClickPlatform(e) {
    if (e.target.checked) {
      this.getSelected("platformY5LChecker", "yes")
      if (e.target.value == "REDCap") {
        this.uncheckElement("eq5dy5l-Platform_qualtrics")
        this.uncheckElement("eq5dy5l-Platform_limesurvey")
      } else if (e.target.value == "Qualtrics") {
        this.uncheckElement("eq5dy5l-Platform_redcap")
        this.uncheckElement("eq5dy5l-Platform_limesurvey")
      } else if (e.target.value == "LimeSurvey") {
        this.uncheckElement("eq5dy5l-Platform_redcap")
        this.uncheckElement("eq5dy5l-Platform_qualtrics")
      }
    } else {
      this.getSelected("platformY5LChecker", "no")
    }
    this.isDigitalChecked()
  }

  eq5d5lClickNextAccordion1(e) {
    const mode_version = this.getCheckedValues('.mode5l:checked')
    let isValid = true
    this.getSelected("selected_mode5l", mode_version)
    if (mode_version == '') {
      alert('please select at least 1 EQ-5D-5L mode. From the questionaire "Who will complete the EQ-5D-5L?"')
      isValid = false
    }
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideElement(e.currentTarget.id)
      this.showHidePrevBtn('prev-tab-5l', 'prev-tab-3-5l')
      this.showElement("next-tab-3-5l")
      this.executeCollapse("eq5d5l-btn-collapse2", "eq5d5lCardTwo")

    }
  }

  eq5d5lClickDevice(e) {
    if (e.target.value == "BYOD") {
      if (e.target.checked) {
        this.checkElement("eq5d5l-Device_pdasmartphone")
        this.checkElement("eq5d5l-Device_tablet")
        this.checkElement("eq5d5l-Device_laptopdesktop")
        this.hideElement("eq5d5l-Device_pdasmartphone-div")
        this.hideElement("eq5d5l-Device_tablet-div")
        this.hideElement("eq5d5l-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5d5l-Device_pdasmartphone")
        this.uncheckElement("eq5d5l-Device_tablet")
        this.uncheckElement("eq5d5l-Device_laptopdesktop")
        this.showElement("eq5d5l-Device_pdasmartphone-div")
        this.showElement("eq5d5l-Device_tablet-div")
        this.showElement("eq5d5l-Device_laptopdesktop-div")
      }
    } else {
      if (this.isChekedElement("eq5d5l-Device_pdasmartphone") && this.isChekedElement("eq5d5l-Device_tablet") && this.isChekedElement("eq5d5l-Device_laptopdesktop")) {
        alert("Selecting All will be default to BYOD only selected")
        this.checkElement("eq5d5l-Device_byod")
        this.hideElement("eq5d5l-Device_pdasmartphone-div")
        this.hideElement("eq5d5l-Device_tablet-div")
        this.hideElement("eq5d5l-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5d5l-Device_byod")
        this.showElement("eq5d5l-Device_pdasmartphone-div")
        this.showElement("eq5d5l-Device_tablet-div")
        this.showElement("eq5d5l-Device_laptopdesktop-div")
      }
    }
    // this.isDeviceChecked()
  }

  eq5dyClickNextAccordion1(e) {
    const mode_version = this.getCheckedValues('.modey:checked')
    let isValid = true
    this.getSelected("selected_modey", mode_version)
    if (mode_version == '') {
      alert('please select at least 1 EQ-5D-Y-3L mode. From the questionaire "Who will complete the EQ-5D-Y-3L?')
      isValid = false
    }
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideElement(e.currentTarget.id)
      this.showHidePrevBtn('prev-tab-y', 'prev-tab-3-y')
      this.showElement("next-tab-3-y")
      this.executeCollapse("eq5dy-btn-collapse2", "eq5dyCardTwo")

    }
  }

  eq5dy5lClickNextAccordion1(e) {
    const mode_version = this.getCheckedValues('.modey5l:checked')
    let isValid = true
    this.getSelected("selected_modey5l", mode_version)
    if (mode_version == '') {
      alert('please select at least 1 EQ-5D-Y-5L mode. From the questionaire "Who will complete the EQ-5D-Y-5L?')
      isValid = false
    }
    if (!isValid) {
      e.preventDefault()
    } else {
      this.hideElement(e.currentTarget.id)
      this.showHidePrevBtn('prev-tab-y-5l', 'prev-tab-3-y-5l')
      this.showElement("next-tab-3-y-5l")
      this.executeCollapse("eq5dY5l-btn-collapse2", "eq5dy5lCardTwo")

    }
  }

  changeBG(e) {
    if (e.target.checked) {
      this.changeBGcolor(e.target.id, "bgThird_y", "bg-white")
    } else {
      this.changeBGcolor(e.target.id, "bg-white", "bgThird_y")
    }
  }

  eq5dyClickDevice(e) {
    if (e.target.value == "BYOD") {
      if (e.target.checked) {
        this.checkElement("eq5dy-Device_pdasmartphone")
        this.checkElement("eq5dy-Device_tablet")
        this.checkElement("eq5dy-Device_laptopdesktop")
        this.hideElement("eq5dy-Device_pdasmartphone-div")
        this.hideElement("eq5dy-Device_tablet-div")
        this.hideElement("eq5dy-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5dy-Device_pdasmartphone")
        this.uncheckElement("eq5dy-Device_tablet")
        this.uncheckElement("eq5dy-Device_laptopdesktop")
        this.showElement("eq5dy-Device_pdasmartphone-div")
        this.showElement("eq5dy-Device_tablet-div")
        this.showElement("eq5dy-Device_laptopdesktop-div")
      }
    } else {
      if (this.isChekedElement("eq5dy-Device_pdasmartphone") && this.isChekedElement("eq5dy-Device_tablet") && this.isChekedElement("eq5dy-Device_laptopdesktop")) {
        alert("Selecting All will be default to BYOD only selected")
        this.checkElement("eq5dy-Device_byod")
        this.hideElement("eq5dy-Device_pdasmartphone-div")
        this.hideElement("eq5dy-Device_tablet-div")
        this.hideElement("eq5dy-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5dy-Device_byod")
        this.showElement("eq5dy-Device_pdasmartphone-div")
        this.showElement("eq5dy-Device_tablet-div")
        this.showElement("eq5dy-Device_laptopdesktop-div")
      }
    }
    // this.isDeviceChecked()
  }

  eq5dy5lClickDevice(e) {
    if (e.target.value == "BYOD") {
      if (e.target.checked) {
        this.checkElement("eq5dy5l-Device_pdasmartphone")
        this.checkElement("eq5dy5l-Device_tablet")
        this.checkElement("eq5dy5l-Device_laptopdesktop")
        this.hideElement("eq5dy5l-Device_pdasmartphone-div")
        this.hideElement("eq5dy5l-Device_tablet-div")
        this.hideElement("eq5dy5l-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5dy5l-Device_pdasmartphone")
        this.uncheckElement("eq5dy5l-Device_tablet")
        this.uncheckElement("eq5dy5l-Device_laptopdesktop")
        this.showElement("eq5dy5l-Device_pdasmartphone-div")
        this.showElement("eq5dy5l-Device_tablet-div")
        this.showElement("eq5dy5l-Device_laptopdesktop-div")
      }
    } else {
      if (this.isChekedElement("eq5dy5l-Device_pdasmartphone") && this.isChekedElement("eq5dy5l-Device_tablet") && this.isChekedElement("eq5dy5l-Device_laptopdesktop")) {
        alert("Selecting All will be default to BYOD only selected")
        this.checkElement("eq5dy5l-Device_byod")
        this.hideElement("eq5dy5l-Device_pdasmartphone-div")
        this.hideElement("eq5dy5l-Device_tablet-div")
        this.hideElement("eq5dy5l-Device_laptopdesktop-div")
      } else {
        this.uncheckElement("eq5dy5l-Device_byod")
        this.showElement("eq5dy5l-Device_pdasmartphone-div")
        this.showElement("eq5dy5l-Device_tablet-div")
        this.showElement("eq5dy5l-Device_laptopdesktop-div")
      }
    }
  }

  isDigitalChecked(e) {
    if (this.getElement("regtype").value == '2') {
      if (this.isChekedElement("eq5d3l-Platform_digital") || this.isChekedElement("eq5d5l-Platform_digital") || this.isChekedElement("eq5dy-Platform_digital") || this.isChekedElement("eq5dy5l-Platform_digital")) {
        this.getElement("unsupportedDigital").value = '1'
      } else {
        this.getElement("unsupportedDigital").value = '0'
      }
      this.checkFastTrack()
    }
  }

  eq5d3lShowDevice(e) {
    this.showElement("eq5d3lShowDevice-div")
  }

  eq5d5lShowDevice(e) {
    this.showElement("eq5d5lShowDevice-div")
  }

  eq5dyShowDevice(e) {
    this.showElement("eq5dyShowDevice-div")
  }

  eq5dy5lShowDevice(e) {
    this.showElement("eq5dy5lShowDevice-div")
  }

  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)

    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    this.getElement(currentTab).classList.remove('text-bold')
    find_tab.click()
  }

  changeOtherVersion(e) {
    this.editInnerHtmlElement('summary-requested-version', e.currentTarget.value)
  }

  executeCollapse(cid, cardid) {
    this.showElement(cardid)
    this.getElement(cid).click()
  }

  showModes3L(e) {
    let div = document.querySelector('#eq5d3lcollapseOne-2')
    let divs = div.querySelectorAll('.d-none')
    let span = document.querySelector('#span-show-modes-3L-show')
    let span2 = document.querySelector('#span-show-modes-3L-hide')

    span2.classList.add('d-flex')
    span2.classList.remove('d-none')
    span.classList.add('d-none')
    span.classList.remove('d-flex')
    for (let i = 2; i < divs.length; i++) {
      if (divs[i]) {
        divs[i].classList.add('d-flex')
        divs[i].classList.remove('d-none')
      }
    }
  }

  hideModes3L(e) {
    let div = document.querySelector('#eq5d3lcollapseOne-2')
    let span = document.querySelector('#span-show-modes-3L-show')
    let span2 = document.querySelector('#span-show-modes-3L-hide')
    let divs = div.querySelectorAll('.d-flex')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  hideModes3LPrev(e) {
    let div = document.querySelector('#eq5d3lcollapseOne-2')
    let span = document.querySelector('#span-show-modes-3L-show')
    let span2 = document.querySelector('#span-show-modes-3L-hide')
    let divs = div.querySelectorAll('.d-flex')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  showModes5L(e) {
    let div = document.querySelector('#eq5d5lcollapseOne-2')
    let divs = div.querySelectorAll('.d-none')
    let span = document.querySelector('#span-show-modes-5L-show')
    let span2 = document.querySelector('#span-show-modes-5L-hide')

    span2.classList.add('d-flex')
    span2.classList.remove('d-none')
    span.classList.add('d-none')
    span.classList.remove('d-flex')
    for (let i = 2; i < divs.length; i++) {
      if (divs[i]) {
        divs[i].classList.add('d-flex')
        divs[i].classList.remove('d-none')
      }
    }
  }

  hideModes5L(e) {
    let div = document.querySelector('#eq5d5lcollapseOne-2')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-5L-show')
    let span2 = document.querySelector('#span-show-modes-5L-hide')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  hideModes5LPrev(e) {
    let div = document.querySelector('#eq5d5lcollapseOne-2')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-5L-show')
    let span2 = document.querySelector('#span-show-modes-5L-hide')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  showModesY(e) {
    let div = document.querySelector('#eq5dycollapseOne-2')
    let divs = div.querySelectorAll('.d-none')
    let span = document.querySelector('#span-show-modes-Y-show')
    let span2 = document.querySelector('#span-show-modes-Y-hide')

    span2.classList.add('d-flex')
    span2.classList.remove('d-none')
    span.classList.add('d-none')
    span.classList.remove('d-flex')
    for (let i = 3; i < divs.length; i++) {
      if (divs[i]) {
        divs[i].classList.add('d-flex')
        divs[i].classList.remove('d-none')
      }
    }
  }

  hideModesY(e) {
    let div = document.querySelector('#eq5dycollapseOne-2')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-Y-show')
    let span2 = document.querySelector('#span-show-modes-Y-hide')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  hideModesYPrev(e) {
    let div = document.querySelector('#eq5dycollapseOne-2')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-Y-show')
    let span2 = document.querySelector('#span-show-modes-Y-hide')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  showModesY5L(e) {
    let div = document.querySelector('#eq5dy5lcollapseOne-2')
    let divs = div.querySelectorAll('.d-none')
    let span = document.querySelector('#span-show-modes-Y-5L-show')
    let span2 = document.querySelector('#span-show-modes-Y-5L-hide')

    span2.classList.add('d-flex')
    span2.classList.remove('d-none')
    span.classList.add('d-none')
    span.classList.remove('d-flex')
    for (let i = 3; i < divs.length; i++) {
      if (divs[i]) {
        divs[i].classList.add('d-flex')
        divs[i].classList.remove('d-none')
      }
    }
  }

  hideModesY5L(e) {
    let div = document.querySelector('#eq5dy5lcollapseOne-2')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-Y-5L-show')
    let span2 = document.querySelector('#span-show-modes-Y-5L-hide')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  hideModesY5LPrev(e) {
    let div = document.querySelector('#eq5dy5lcollapseOne-2')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-Y-5L-show')
    let span2 = document.querySelector('#span-show-modes-Y-5L-hide')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')
      span2.classList.add('d-none')
      span2.classList.remove('d-flex')

      for (let i = 0; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }
}
