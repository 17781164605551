import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "icon", "text" ]

  show() {
    this.textTarget.style.visibility = "visible";
    this.textTarget.style.opacity = "1";
  }

  hide() {
    this.textTarget.style.visibility = "hidden";
    this.textTarget.style.opacity = "0";
  }
}