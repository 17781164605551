import { Controller } from "stimulus"

export default class extends Controller {

  checkEmail(email) {
    let validEmail = true
    let validFormat = this.getElement('required-email-valid')
    if (!this.emailIsValid(this.getElement(email).value)) {
      this.getElement(email).focus()
      validFormat.classList.remove("d-none")
      validEmail = false
    } else {
      validFormat.classList.add("d-none")
    }
    return validEmail
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  checkBlank(valid, id, errorid) {
    let isBlank = valid
    const element = this.getElement(id)
    const errorelem = this.getElement(errorid)
    if (element.value == '') {
      element.focus()
      errorelem.classList.remove("d-none")
      isBlank = false
    } else {
      errorelem.classList.add("d-none")
    }
    return isBlank
  }

  checkDate(valid, id, errorid) {
    let isBlank = valid
    const element = this.getElement(id)
    const errorelem = this.getElement(errorid)
    if (parseInt(element.value) < 0) {
      element.focus()
      errorelem.classList.remove("d-none")
      isBlank = false
    } else {
      errorelem.classList.add("d-none")
    }
    return isBlank
  }

  checkDisabledBlank(valid, id, errorid) {
    let isBlankDisabled = valid
    const element = this.getElement(id)
    const errorelem = this.getElement(errorid)
    if (!element.disabled && element.value == '') {
      element.focus()
      errorelem.classList.remove("d-none")
      isBlankDisabled = false
    } else {
      errorelem.classList.add("d-none")
    }
    return isBlankDisabled
  }

  checkRadioBlank(valid, id1, id2, errorid) {
    let isRadioBlank = valid
    const errorelem = this.getElement(errorid)
    if (!this.isChekedElement(id1) && !this.isChekedElement(id2)) {
      this.getElement(id2).focus()
      errorelem.classList.remove("d-none")
      isRadioBlank = false
    } else {
      errorelem.classList.add("d-none")
    }
    return isRadioBlank
  }

  checkStartDateIsGreater(valid, id1, id2, errorid) {
    let isValid = valid
    const element1 = this.getElement(id1)
    const element2 = this.getElement(id2)
    const errorelem = this.getElement(errorid)
    if (element1.value > element2) {
      this.getElement(id2).focus()
      errorelem.innerHTML = "<small>End date must be equal to or after Start date.</small>"
      errorelem.classList.remove("d-none")
      isValid = false
    } else {
      errorelem.classList.add("d-none")
    }
    return isValid
  }

  checkMaximumTerm(valid, id1, id2, errorid) {
    let isValid = valid
    let regType = this.getElement("regtype")
    if (regType.value == '2') {
      const element1 = this.getElement(id1)
      const element2 = this.getElement(id2)
      const errorelem = this.getElement(errorid)
      let startD = new Date(element1.value)
      let endD = new Date(element2.value)
      let duration = this.dateDiffInYears(startD, endD)
      if (duration >= 10) {
        this.getElement(id2).focus()
        errorelem.innerHTML = "<small>Maximum term for which EQ-5D registration can be arranged is 10 years. You are requested to re-register after this term.</small>"
        errorelem.classList.remove("d-none")
        isValid = false
      } else {
        errorelem.classList.add("d-none")
      }
    }
    return isValid
  }

  dateDiffInYears(dateold, datenew) {
    let ynew = datenew.getFullYear()
    let mnew = datenew.getMonth()
    let dnew = datenew.getDate() - 1
    let yold = dateold.getFullYear()
    let mold = dateold.getMonth()
    let dold = dateold.getDate()
    let diff = ynew - yold
    if (mold > mnew) diff--
    else {
      if (mold == mnew) {
        if (dold > dnew) diff--
      }
    }
    return diff
  }

  checkRadioDisabledBlank(valid, id1, id2, errorid) {
    let isRadioBlank = valid
    const element1 = this.getElement(id1)
    const element2 = this.getElement(id2)
    const errorelem = this.getElement(errorid)
    if ((!this.isChekedElement(id1) && !element1.disabled) && (!this.isChekedElement(id2) && !element2.disabled)) {
      this.getElement(id2).focus()
      errorelem.classList.remove("d-none")
      isRadioBlank = false
    } else {
      errorelem.classList.add("d-none")
    }
    return isRadioBlank
  }

  getElement(id) {
    return document.getElementById(id)
  }

  showClasses(cs) {
    var elements = document.getElementsByClassName(cs);
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove('d-none')
    }
  }

  hideClasses(cs) {
    var elements = document.getElementsByClassName(cs);
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add('d-none')
    }
  }

  isChekedElement(id) {
    let isChecked = (document.getElementById(id).checked) ? true : false
    return isChecked
  }

  checkElement(id) {
    document.getElementById(id).checked = true
  }

  uncheckElement(id) {
    document.getElementById(id).checked = false
  }

  showElement(id) {
    document.getElementById(id).classList.remove('d-none')
  }

  hideElement(id) {
    document.getElementById(id).classList.add('d-none')
  }

  disableElement(id) {
    document.getElementById(id).disabled = true
  }

  enableElement(id) {
    document.getElementById(id).disabled = false
  }

  changeBGcolor(id, color1, color2) {
    document.getElementById(id).closest('.roundBoxBorder').classList.add(color1)
    document.getElementById(id).closest('.roundBoxBorder').classList.remove(color2)
  }

  editInnerHtmlElement(id, value) {
    document.getElementById(id).innerHTML = value
  }

  getSelected(id, value) {
    document.getElementById(id).value = value
  }

  getCheckedValues(inputCheckbox) {
    const type = inputCheckbox
    const data = [...document.querySelectorAll(type)].map(e => e.value);

    return data
  }

  checkDigital() {
    if (this.getElement("regtype").value == '2') {
      let rav = this.getCheckedValues('.availabe_version:checked')
      let ravDigital = rav.some(a => a.includes('Digital'))
      let ruv = this.getCheckedValues('.unavailable_version:checked')
      let ruvDigital = ruv.some(a => a.includes('Digital'))
      ravDigital || ruvDigital ? this.getElement("unsupportedDigital").value = '1' : this.getElement("unsupportedDigital").value = '0'
      this.checkFastTrack()
    }
  }

  checkUnavailable() {
    let ruv = this.getCheckedValues('.unavailable_version:checked')
    ruv.length > 0 ? this.getElement("unavailableMoa").value = '1' : this.getElement("unavailableMoa").value = '0'
    if (this.getElement("regtype").value == '2') {
      this.checkFastTrack()
    }
  }

  checkTotalCheckedProducts() {
    var availableChecklist = document.getElementsByClassName('availabe_version')
    var unavailableChecklist = document.getElementsByClassName('unavailable_version')
    let totalLength = 0
    if (availableChecklist.length == 0 && unavailableChecklist.length == 0) {
      totalLength = 0
    } else {
      let rav = this.getCheckedValues('.availabe_version:checked')
      let ruv = this.getCheckedValues('.unavailable_version:checked')
      totalLength = rav.length + ruv.length
    }
    return totalLength;
  }

  getOtherCountryLanguage(country, language, selected_language) {
    const other_country = this.getByClassName(country)
    const other_language = this.getByClassName(language)
    const remove_blank = selected_language.map(language => language.filter(lang => lang !== ""))
    let merge_country_language = []

    other_country.map((country, index) => merge_country_language.push([other_language[index] + ' ', country[0]]))
    if (other_language.length == 0) other_language[0] = '';
    return (other_country[0] == "" || other_language[0] == "") ? remove_blank : [...remove_blank, ...merge_country_language]
  }

  getByClassName(className) {
    let get_array = [...document.querySelectorAll(className)].map(e => e.value.trim().split())
    get_array = get_array.filter(array => array[0] != "")
    return get_array
  }

  checkFastTrack() {
    if (this.getElement("regtype").value == '2') {
      let np_limit = parseInt(this.getElement("np_limit").value)
      let noPatients = parseInt(this.getElement("noPatients").value)
      // let studyDuration = parseInt(this.getElement("studyDuration").value)
      let unsupportedDigital = parseInt(this.getElement("unsupportedDigital").value)
      let unavailableMoa = parseInt(this.getElement("unavailableMoa").value)
      let toModify = parseInt(this.getElement("toModify").value)

      let badge = this.getElement("ft-string")
      let badgeInfo = this.getElement("ft-info")
      let badgeContainer = this.getElement("ft-container")
      let regRoute = this.getElement("00N1r00000KdUpA")
      let regDeliveryEstimate = this.getElement("00N1r00000KdUp6")
      let dsBtn = this.getElement('btn-modal')
      let btnSubmit = this.getElement('btn-submit-form')

      // if (noPatients <= np_limit && studyDuration < 5 && unsupportedDigital == 0 && unavailableMoa == 0 && toModify == 0) {
      if (noPatients <= np_limit && unsupportedDigital == 0 && unavailableMoa == 0 && toModify == 0) {
        badge.innerHTML = 'Fast Track'
        dsBtn.classList.remove('d-none')
        btnSubmit.classList.add('d-none')
        badge.classList.remove('eqTextStandardBorder')
        badge.classList.add('eqTextFastBorder')
        badgeContainer.classList.remove('infoBoxStandard')
        badgeContainer.classList.add('infoBoxOrange')
        badgeContainer.setAttribute('data-original-title', 'No license agreement needed. We aim to deliver EQ-5D within 2 business days after you have agreed to the Terms of Use.');
        badgeInfo.classList.remove('eqText-standard')
        badgeInfo.classList.add('eqText-danger')

        this.hideClasses("fast-track-note")
        this.hideClasses("note-list-np")
        this.hideClasses("note-list-np-proms")
        // this.hideClasses("note-list-duration")
        // this.hideClasses("note-list-digital")
        this.hideClasses("note-list-unavailable")
        this.hideClasses("note-list-modify")

        this.getSelected("ft_checker_np", "f")
        this.getSelected("ft_checker_np_proms", "f")
        // this.getSelected("ft_checker_sd", "f")
        // this.getSelected("ft_checker_ud", "f")
        this.getSelected("ft_checker_um", "f")
        this.getSelected("ft_checker_tm", "f")

        regRoute.value = 'Fast Track'
        regDeliveryEstimate.value = 'We aim to deliver EQ-5D within 2 business days after you have agreed to the Terms of Use.'
      } else if (noPatients <= np_limit && unsupportedDigital > 0 && unavailableMoa == 0 && toModify == 0) {
        // old fast track digital
        badge.innerHTML = 'Fast Track'
        dsBtn.classList.remove('d-none')
        btnSubmit.classList.add('d-none')
        badge.classList.remove('eqTextStandardBorder')
        badge.classList.add('eqTextFastBorder')
        badgeContainer.classList.remove('infoBoxStandard')
        badgeContainer.classList.add('infoBoxOrange')
        badgeContainer.setAttribute('data-original-title', 'No license agreement needed. We aim to deliver EQ-5D within 2 business days after you have agreed to the Terms of Use.');
        badgeInfo.classList.remove('eqText-standard')
        badgeInfo.classList.add('eqText-danger')

        this.hideClasses("fast-track-note")
        this.hideClasses("note-list-np")
        this.hideClasses("note-list-np-proms")
        this.hideClasses("note-list-unavailable")
        this.hideClasses("note-list-modify")

        this.getSelected("ft_checker_np", "f")
        this.getSelected("ft_checker_np_proms", "f")
        this.getSelected("ft_checker_um", "f")
        this.getSelected("ft_checker_tm", "f")

        regRoute.value = 'Fast Track'
        regDeliveryEstimate.value = 'We aim to deliver EQ-5D within 2 business days after you have agreed to the Terms of Use.'
      } else {
        badge.innerHTML = 'Standard'
        dsBtn.classList.add('d-none')
        btnSubmit.classList.remove('d-none')
        badge.classList.remove('eqTextFastBorder')
        badge.classList.add('eqTextStandardBorder')
        badgeContainer.classList.add('infoBoxStandard')
        badgeContainer.classList.remove('infoBox')
        badgeContainer.setAttribute('data-original-title', 'A license agreement is needed. EQ-5D can be only delivered once an agreement has been set up, negotiated and signed. Within 5 business days a representative from the EuroQol Office will be in contact.');
        badgeInfo.classList.add('eqText-standard')
        badgeInfo.classList.remove('eqText-danger')

        this.showClasses("fast-track-note")

        regRoute.value = 'Standard'
        regDeliveryEstimate.value = 'Within 5 business days a representative from the EuroQol Office will be in contact.'

        if (noPatients <= np_limit) {
          this.hideClasses("note-list-np")
          this.hideClasses("note-list-np-proms")
          this.getSelected("ft_checker_np", "f")
          this.getSelected("ft_checker_np_proms", "f")
        } else {
          if (np_limit == '10000') {
            this.showClasses("note-list-np")
            this.hideClasses("note-list-np-proms")
            this.getSelected("ft_checker_np", "s")
            this.getSelected("ft_checker_np_proms", "f")
          } else {
            this.hideClasses("note-list-np")
            this.showClasses("note-list-np-proms")
            this.getSelected("ft_checker_np", "f")
            this.getSelected("ft_checker_np_proms", "s")
          }
        }

        // if (studyDuration < 5) {
        //   this.hideClasses("note-list-duration")
        //   this.getSelected("ft_checker_sd", "f")
        // } else {
        //   this.showClasses("note-list-duration")
        //   this.getSelected("ft_checker_sd", "s")
        // }

        if (unsupportedDigital == 0) {
          // this.hideClasses("note-list-digital")
          // this.getSelected("ft_checker_ud", "f")
        } else {
          // this.showClasses("note-list-digital")
          // this.getSelected("ft_checker_ud", "s")
        }

        if (unavailableMoa == 0) {
          this.hideClasses("note-list-unavailable")
          this.getSelected("ft_checker_um", "f")
        } else {
          this.showClasses("note-list-unavailable")
          this.getSelected("ft_checker_um", "s")
        }

        if (toModify == 0) {
          this.hideClasses("note-list-modify")
          this.getSelected("ft_checker_tm", "f")
        } else {
          this.showClasses("note-list-modify")
          this.getSelected("ft_checker_tm", "s")
        }
      }
    }
  }

  showSummary() {
    let regRoute = this.getElement("00N1r00000KdUpA")
    let desiredDate = this.getElement("summary-desired-date")
    // this.editInnerHtmlElement("summary-requestedavailable", this.getElement("00N1r00000HHVAT").value)
    // this.editInnerHtmlElement("summary-requestedunavailable", this.getElement("00N1r00000HHVAU").value)

    if (regRoute.value == 'Priority') {
      this.showElement("summary-priority")
      this.hideElement("summary-fastrack")
      this.hideElement("summary-fastrack-digital")
      // this.hideElement("summary-standard-a")
      this.hideElement("summary-standard-b")
    } else if (regRoute.value == 'Fast Track') {
      this.hideElement("summary-priority")
      this.showElement("summary-fastrack")
      this.hideElement("summary-fastrack-digital")
      // this.hideElement("summary-standard-a")
      this.hideElement("summary-standard-b")
    } else if (regRoute.value == 'Standard') {
      this.hideElement("summary-priority")
      this.hideElement("summary-fastrack")
      this.hideElement("summary-fastrack-digital")
      // this.showElement("summary-standard-a")
      this.showElement("summary-standard-b")
    } else if (regRoute.value == 'Fast Track - Digital') {
      this.hideElement("summary-priority")
      this.hideElement("summary-fastrack")
      this.showElement("summary-fastrack-digital")
      // this.showElement("summary-standard-a")
      this.hideElement("summary-standard-b")
    }
    if (this.getElement("00N1r00000KX5hP").value == '1') {
      this.showElement("summary-unsure")
    } else {
      this.hideElement("summary-unsure")
    }
    if (this.getElement("email_status").value == 'good') {
      this.hideElement("summary-email-status")
    } else {
      this.showElement("summary-email-status")
    }
    if (this.getElement("00N1r00000HHVAU").value.trim() != "") {
      desiredDate.classList.remove('d-none')
      desiredDate.classList.add('d-flex')
      this.enableElement("00N1r00000HHVA3")
    } else {
      desiredDate.classList.add('d-none')
      desiredDate.classList.remove('d-flex')
      this.disableElement("00N1r00000HHVA3")
    }
    if (parseInt(this.getElement("unavailableMoa").value) == 0) {
      this.hideElement("summary-unavailable-note")
    } else {
      this.showElement("summary-unavailable-note")
    }
  }

  setRouteDetials() {
    if (this.getElement("regtype").value == "2") {
      let regRoute = this.getElement("00N1r00000KdUpA")
      let fastrackText = `Fast track enabled. After pressing “Send” you will receive an email with our Terms of Use. We aim to deliver EQ-5D within 2 business days after you have agreed to the Terms of Use.`
      let standardText = "Fast Track is no longer possible. A license agreement is needed because:\n"

      standardText = this.getElement("ft_checker_np").value == "s" ? standardText += "Number of patients &gt; 10000\n" : standardText
      standardText = this.getElement("ft_checker_np_proms").value == "s" ? standardText += "Study design = PROMs and Number of patients &gt; 100000\n" : standardText
      // standardText = this.getElement("ft_checker_sd").value == "s" ? standardText += "Study/project duration is longer than 5 years\n" : standardText
      // standardText = this.getElement("ft_checker_ud").value == "s" ? standardText += "A digital platform was selected for which screenshot review is required, at a fee of €600.\n" : standardText
      standardText = this.getElement("ft_checker_um").value == "s" ? standardText += "An unavailable EQ-5D version was selected, which can be created for you. Note that this may take months and that a translation fee may be charged.\n" : standardText
      standardText = this.getElement("ft_checker_tm").value == "s" ? standardText += "Planning to modify EQ-5D: Yes\n" : standardText
      standardText += "After pressing “Send” a confirmatory email will be sent. Within 5 business days a representative from the EuroQol Office will be in contact.\n"

      regRoute.value == 'Standard' ? this.getSelected("00N1r00000KdUp9", standardText) : this.getSelected("00N1r00000KdUp9", fastrackText)
    }
  }

  setQualified() {
    if (this.getElement("00N1r00000KX5hP").value == "1") {
      this.getSelected("00N0E000007wbw9", "no")
    } else {
      if (this.getElement("regtype").value == "1") {
        this.getSelected("00N0E000007wbw9", "yes")
      } else {
        if (this.getElement("workenv_qualify").value == "n" || this.getElement("ft_checker_np_proms").value == "s" || this.getElement("useinapp_qualify").value == "n" || this.getElement("ft_checker_tm").value == "s" || this.getElement("ft_checker_um").value == "s") {
          this.getSelected("00N0E000007wbw9", "no")
        } else {
          this.getSelected("00N0E000007wbw9", "yes")
        }
      }
    }
  }

  hideOrShowPlatform() {
    if (this.getElement("regtype").value == "1") {
      this.hideElement("eq5d3l-Platform_redcap-div")
      this.hideElement("eq5d3l-Platform_qualtrics-div")
      this.hideElement("eq5d3l-Platform_limesurvey-div")

      this.hideElement("eq5d5l-Platform_redcap-div")
      this.hideElement("eq5d5l-Platform_qualtrics-div")
      this.hideElement("eq5d5l-Platform_limesurvey-div")

      this.hideElement("eq5dy-Platform_redcap-div")
      this.hideElement("eq5dy-Platform_qualtrics-div")
      this.hideElement("eq5dy-Platform_limesurvey-div")
    } else {
      this.showElement("eq5d3l-Platform_redcap-div")
      this.showElement("eq5d3l-Platform_qualtrics-div")
      this.showElement("eq5d3l-Platform_limesurvey-div")

      this.showElement("eq5d5l-Platform_redcap-div")
      this.showElement("eq5d5l-Platform_qualtrics-div")
      this.showElement("eq5d5l-Platform_limesurvey-div")

      this.showElement("eq5dy-Platform_redcap-div")
      this.showElement("eq5dy-Platform_qualtrics-div")
      this.showElement("eq5dy-Platform_limesurvey-div")
    }
  }

  removeAllChild(parent) {
    let child = parent.firstChild
    while (child) {
      parent.removeChild(child) // or just: child.remove()
      child = parent.firstChild
    }
  }

  validateFields(target_tab) {
    let isValid = true
    let tab = this.getElement("current_tab").value

    if (target_tab >= parseFloat(tab)) {
      if (tab == "1") {
        isValid ? isValid = this.checkBlank(isValid, "title", "required-title") : isValid = false
        isValid ? isValid = this.checkRadioBlank(isValid, "meg_yes", "meg_no", 'required-meg') : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "first_name", "required-first_name") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "last_name", "required-last_name") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "email", "required-email") ? this.checkEmail("email") : false : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "country", "required-country") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "mobile", "required-phone") : isValid = false
        isValid ? this.getSelected("valid_tab1", "yes") : this.getSelected("valid_tab1", "no")
        isValid ? this.getElement("mystep-1").classList.add('valid') : this.getElement("mystep-1").classList.remove('valid')
      } else if (tab == "1.2") {
        isValid ? isValid = this.checkBlank(isValid, "company", "required-company") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "00N1r00000HHVAd", "required-00N1r00000HHVAd") : isValid = false
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000HHVAL", "required-00N1r00000HHVAL") : isValid = false
        // isValid ? isValid = this.checkBlank(isValid, "phone", "required-telephone") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "street", "required-street") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "city", "required-city") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "zip", "required-zip") : isValid = false
        isValid ? this.getSelected("valid_tab1p2", "yes") : this.getSelected("valid_tab1p2", "no")
        isValid ? this.getElement("mystep-1p2").classList.add('valid') : this.getElement("mystep-1p2").classList.remove('valid')
      } else if (tab == "2") {
        isValid = this.checkBlank(isValid, "00N1r00000HHVAb", "required-00N1r00000HHVAb")
        isValid ? isValid = this.checkBlank(isValid, "00N1r00000HHVAH", "required-00N1r00000HHVAH") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "00N1r00000HHVAW", "required-00N1r00000HHVAW") : isValid = false
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000HHVA8", "required-00N1r00000HHVA8") : isValid = false
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000HHVAI", "required-00N1r00000HHVAI") : isValid = false
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000HHVA4", "required-00N1r00000HHVA4") : isValid = false
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000HHVAK", "required-00N1r00000HHVAK") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "00N1r00000HHVA1", "required-00N1r00000HHVA1") : isValid = false
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000HHVAJ", "required-00N1r00000HHVAJ") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "00N1r00000HHVAG", "required-00N1r00000HHVAG") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "00N1r00000HHVAX", "required-00N1r00000HHVAX") : isValid = false
        isValid ? isValid = this.checkBlank(isValid, "00N1r00000HHVAA", "required-00N1r00000HHVAA") : isValid = false
        isValid ? isValid = this.checkStartDateIsGreater(isValid, "00N1r00000HHVAX", "00N1r00000HHVAA", "required-00N1r00000HHVAA") : isValid = false
        isValid ? isValid = this.checkMaximumTerm(isValid, "00N1r00000HHVAX-pickr", "00N1r00000HHVAA-pickr", "required-00N1r00000HHVAA") : isValid = false
        isValid ? isValid = this.checkRadioDisabledBlank(isValid, "00N1r00000KXPSh_1", "00N1r00000KXPSh_0", "required-00N1r00000KXPSh") : isValid = false
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000KXPSg", "required-00N1r00000KXPSg") : isValid = false
        isValid ? isValid = this.checkDate(isValid, "studyDuration", "required-00N1r00000HHVAA") : isValid = false
        isValid ? this.getSelected("valid_tab2", "yes") : this.getSelected("valid_tab2", "no")
        isValid ? this.getElement("mystep-2").classList.add('valid') : this.getElement("mystep-2").classList.remove('valid')
      } else if (tab == "3") {
        const version = this.getCheckedValues('.version:checked')
        this.getSelected("selected_version", version)
        if (version == '') {
          this.showElement("required-Level")
          isValid = false
        } else {
          this.hideElement("required-Level")
        }
        isValid ? this.getSelected("valid_tab3", "yes") : this.getSelected("valid_tab3", "no")
        isValid ? this.getElement("mystep-3").classList.add('valid') : this.getElement("mystep-3").classList.remove('valid')
      } else if (tab == "3.1") {
        const mode_version = this.getCheckedValues('.mode3l:checked')
        const platform = this.getCheckedValues('.platform3l:checked')
        const device = this.getCheckedValues('.device3l:checked')
        if (mode_version == '') {
          alert('please select at least 1 EQ-5D-3L mode. From the questionaire "Who will complete the EQ-5D-3L?"')
          isValid = false
        }
        if (device == '' && platform == '') {
          alert('please select at least 1 EQ-5D-3L device or platform. From the questionaire "How will the EQ-5D-3L data be collected?"')
          isValid = false
        }
      } else if (tab == "3.2") {
        const mode_version = this.getCheckedValues('.mode5l:checked')
        const platform = this.getCheckedValues('.platform5l:checked')
        const device = this.getCheckedValues('.device5l:checked')
        if (mode_version == '') {
          alert('please select at least 1 EQ-5D-5L mode. From the questionaire "Who will complete the EQ-5D-5L?"')
          isValid = false
        }
        if (device == '' && platform == '') {
          alert('please select at least 1 EQ-5D-5L device or platform. From the questionaire "How will the EQ-5D-5L data be collected?"')
          isValid = false
        }
      } else if (tab == "3.3") {
        const mode_version = this.getCheckedValues('.modey:checked')
        const platform = this.getCheckedValues('.platformy:checked')
        const device = this.getCheckedValues('.devicey:checked')
        if (mode_version == '') {
          alert('please select at least 1 EQ-5D-Y-3L mode. From the questionaire "Who will complete the EQ-5D-Y-3L?"')
          isValid = false
        }
        if (device == '' && platform == '') {
          alert('please select at least 1 EQ-5D-Y-3L device or platform. From the questionaire "How will the EQ-5D-Y-3L data be collected?"')
          isValid = false
        }
      } else if (tab == "3.4") {
        const mode_version = this.getCheckedValues('.modey5l:checked')
        const platform = this.getCheckedValues('.platformy5l:checked')
        const device = this.getCheckedValues('.devicey5l:checked')
        if (mode_version == '') {
          alert('please select at least 1 EQ-5D-Y-5L mode. From the questionaire "Who will complete the EQ-5D-Y-5L?"')
          isValid = false
        }
        if (device == '' && platform == '') {
          alert('please select at least 1 EQ-5D-Y-5L device or platform. From the questionaire "How will the EQ-5D-Y-5L data be collected?"')
          isValid = false
        }
      } else if (tab == "4") {
        const selected_language = [...document.querySelectorAll('.selected_language3l')].map(e => e.innerHTML.trim().split(/[()]/));
        const get_selected_language = this.getOtherCountryLanguage('.addCountry3L', '.addLangauge3L', selected_language)
        isValid = get_selected_language.length > 0 ? true : false
        isValid ? this.getSelected("valid_tab4", "yes") : this.getSelected("valid_tab4", "no")
        isValid ? this.getElement("mystep-4").classList.add('valid') : this.getElement("mystep-4").classList.remove('valid')
      } else if (tab == "5") {
        isValid = this.checkTotalCheckedProducts() > 0 ? true : false
        isValid ? this.getSelected("valid_tab5", "yes") : this.getSelected("valid_tab5", "no")
        isValid ? this.getElement("mystep-5").classList.add('valid') : this.getElement("mystep-5").classList.remove('valid')
      } else if (tab == "6") {
        isValid = this.checkRadioBlank(isValid, "00N1r00000HHVAN_1", "00N1r00000HHVAN_0", "required-00N1r00000HHVAN")
        isValid ? isValid = this.checkDisabledBlank(isValid, "00N1r00000HHVAE", "required-00N1r00000HHVAE") : isValid = false
        isValid ? isValid = this.checkRadioBlank(isValid, "00N1r00000HHVAP_1", "00N1r00000HHVAP_0", "required-00N1r00000HHVAP") : isValid = false
        isValid ? isValid = this.checkRadioBlank(isValid, "00N1r00000HHVAe_1", "00N1r00000HHVAe_0", "required-00N1r00000HHVAe") : isValid = false
        isValid ? isValid = this.checkRadioBlank(isValid, "cs_yes", "cs_no", 'req-cs') : isValid = false
        if (isValid) {
          if (!this.isChekedElement("00N1r00000HHVAY-checker_1")) {
            this.getElement("00N1r00000HHVAY-checker_1").focus()
            this.showElement("required-00N1r00000HHVAY")
            isValid = false
          } else {
            this.hideElement("required-00N1r00000HHVAY")
          }
        }
        isValid ? this.getSelected("valid_tab6", "yes") : this.getSelected("valid_tab6", "no")
        isValid ? this.getElement("mystep-6").classList.add('valid') : this.getElement("mystep-6").classList.remove('valid')
      }
    }
    return isValid;
  }


}

