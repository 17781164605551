import SelectingMethodController from './selecting_method_controller'

export default class extends SelectingMethodController {
  static targets = ["removeBtnOtherLang", "divOtherLang", "addBtnOtherLang"]

  connect() {
    this.preventPaste()
  }

  countrySearch3L(e) {
    this.countrySearch(e, "ms-Country3L")
  }

  countrySearch5L(e) {
    this.countrySearch(e, "ms-Country5L")
  }

  countrySearchY(e) {
    this.countrySearch(e, "ms-CountryY")
  }

  countrySearch(e, countrySelect) {
    let searchString = e.target.value.toLowerCase().trim()
    let usaAlt = "united states of america"
    let ukAlt1 = "united kingdom"
    let ukAlt2 = "england"
    let ukAlt3 = "great britain"
    let uaeAlt = "united arab emirates"
    let unitedAlt = "united"
    let usaString = "(usa)"
    let ukString = "(uk)"
    let uaeString = "(uae)"

    let isUnited = unitedAlt.includes(searchString)
    let isUSA = usaAlt.includes(searchString)
    let isUK = ukAlt1.includes(searchString) || ukAlt2.includes(searchString) || ukAlt3.includes(searchString)
    let isUAE = uaeAlt.includes(searchString)

    let multiSelect = this.getElement(countrySelect)
    let toHide = multiSelect.querySelectorAll("li.ms-elem-selectable:not([data-search*='" + searchString + "'])")
    let toShow = multiSelect.querySelectorAll("li.ms-elem-selectable[data-search*='" + searchString + "']")
    let toShowUSA = multiSelect.querySelectorAll("li.ms-elem-selectable[data-search*='" + usaString + "']")
    let toShowUK = multiSelect.querySelectorAll("li.ms-elem-selectable[data-search*='" + ukString + "']")
    let toShowUAE = multiSelect.querySelectorAll("li.ms-elem-selectable[data-search*='" + uaeString + "']")
    let selectedHide = multiSelect.querySelectorAll("li.ms-elem-selectable.selected_language")
    let groupsToHide = multiSelect.querySelectorAll("li.ms-optgroup-container");

    if (searchString != '') {
      groupsToHide.forEach(el => el.style.display = "none")
      toShow.forEach(el => el.closest("li.ms-optgroup-container").style.display = "block")
      toShow.forEach(el => el.style.display = "block")
      toHide.forEach(el => el.style.display = "none")
      selectedHide.forEach(el => el.style.display = "none")
      if (isUnited) {
        toShowUSA.forEach(el => el.closest("li.ms-optgroup-container").style.display = "block")
        toShowUSA.forEach(el => el.style.display = "block")
        toShowUK.forEach(el => el.closest("li.ms-optgroup-container").style.display = "block")
        toShowUK.forEach(el => el.style.display = "block")
        toShowUAE.forEach(el => el.closest("li.ms-optgroup-container").style.display = "block")
        toShowUAE.forEach(el => el.style.display = "block")
      } else {
        if (isUSA) {
          toShowUSA.forEach(el => el.closest("li.ms-optgroup-container").style.display = "block")
          toShowUSA.forEach(el => el.style.display = "block")
        }
        if (isUK) {
          toShowUK.forEach(el => el.closest("li.ms-optgroup-container").style.display = "block")
          toShowUK.forEach(el => el.style.display = "block")
        }
        if (isUAE) {
          toShowUAE.forEach(el => el.closest("li.ms-optgroup-container").style.display = "block")
          toShowUAE.forEach(el => el.style.display = "block")
        }
      }
    } else {
      groupsToHide.forEach(el => el.style.display = "block")
      toShow.forEach(el => el.closest("li.ms-optgroup-container").style.display = "none")
      toShow.forEach(el => el.style.display = "none")
      toHide.forEach(el => el.style.display = "block")
      selectedHide.forEach(el => el.style.display = "none")
    }
  }

  addLanguage3L(e) {
    let get_id = e.currentTarget.id
    let result = get_id.split('-')
    let count = parseInt(result[1]) + 1
    let div = this.getElement("addCountryDiv3L")
    let closestCountryValue = this.getElement(get_id).closest(".addParent").querySelector(".addCountry3L").value.trim()
    let closestLanguageValue = this.getElement(get_id).closest(".addParent").querySelector(".addLangauge3L").value.trim()
    if (closestCountryValue != "" && closestLanguageValue != "") {
      let htmlString = '<div class="addParent ml-md-4" id="addedDiv3L' + count + '" data-number="' + count + '" data-select-country-language-target="divOtherLang">'
      htmlString += '<div class="row col-sm-12 col-md-12">'
      htmlString += '<div class="form-group pl-0 pr-1 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addCountry3L-' + count + '" class="form-control addCountry3L no-paste" name="otherCountry3L[]" data-action="keyup->western#validateInput">'
      htmlString += '</div>'
      htmlString += '<div class="form-group pl-1 pr-0 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addLangauge3L-' + count + '" class="form-control addLangauge3L no-paste" name="otherLanguage3L[]" data-action="keyup->western#validateInput">'
      htmlString += '</div>'
      htmlString += '<div class="col-sm-2 col-md-2 px-0">'
      htmlString += '<span class="remove3L" id="remove3L-' + count + '" data-action="click->select-country-language#remove3LDivAdded" data-select-country-language-target="removeBtnOtherLang"><svg xmlns="http://www.w3.org/2000/svg" class="text-danger" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '<span class="add3L mr-1" id="plus3L-' + count + '" data-action="click->select-country-language#addLanguage3L" data-select-country-language-target="addBtnOtherLang"><svg xmlns="http://www.w3.org/2000/svg" class="eqText-success" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '</div>'
      htmlString += '</div>'
      htmlString += '</div>'
      div.insertAdjacentHTML('beforeend', htmlString);
      this.preventPaste();
      const remove_btn = this.getElement('remove3L-' + parseInt(result[1]) + '')
      if (remove_btn) {
        this.hideElement(remove_btn.id)
      }
      this.hideElement(get_id)
      let insertOtherCountryLanguage = closestLanguageValue + " (" + closestCountryValue + ")"
      insertOtherCountryLanguage = this.getElement("otherCountryLanguage3L").value == '' ? insertOtherCountryLanguage : this.getElement("otherCountryLanguage3L").value += "\n" + insertOtherCountryLanguage
      this.getSelected("otherCountryLanguage3L", insertOtherCountryLanguage)
    } else {
      alert('Please input Other Country/region + Language')
    }
  }

  remove3LDivAdded(e) {
    this.removeDivLanguage(e, 'remove3L-', 'plus3L-', 'addedDiv3L')
  }

  removeDivLanguage(e, removeId, addId, addDivId) {
    let get_id = e.currentTarget.id
    const get_count = get_id.split('-')
    let result = parseInt(get_count[1]) - 1
    const remove_div = this.getElement('' + addDivId + get_count[1] + '')
    const remove_btn = this.getElement('' + removeId + '' + result + '')
    if (remove_btn) {
      this.showElement(remove_btn.id)
    }
    this.showElement('' + addId + result + '')
    remove_div.remove()
  }

  cancelOther(e) {
    if (confirm("Are you sure you want to reset? If you want to save/update your changes, click the `Save` button")) {
      let parent = document.querySelector('#addCountryDiv3L')
      this.removeAllChild(parent)
      this.addFirstChildOther(parent)
      document.querySelector('#showOtherCountry').innerHTML = ''
      this.getElement('modalCloseOther').click()
      let trow = this.getElement("custom-trow")
      trow.classList.add('d-none')
    }
  }

  saveOther(e) {
    this.showOtherCountryFromModal()
    this.getElement('modalCloseOther').click()
  }

  addFirstChildOther(parent) {
    let htmlString = '<div class="addParent ml-md-4" data-number="1">'
    htmlString += '<div class="row col-sm-12 col-md-12">'
    htmlString += '<div class="form-group pl-0 pr-1 col-sm-5 col-md-5">'
    htmlString += '<strong>Country/region</strong>'
    htmlString += '<input type="text" id="addCountry3L-1"  class="form-control mt-1 addCountry3L no-paste" name="otherCountry3L[]"></div>'
    htmlString += '<div class="form-group pl-1 pr-0 col-sm-5 col-md-5"><strong>Language</strong>'
    htmlString += '<input type="text" id="addLangauge3L-1"  class="form-control mt-1 addLangauge3L no-paste" name="otherLanguage3L[]"></div>'
    htmlString += '<div class="col-sm-2 col-md-2 px-0 pt-4">'
    htmlString += '<span class="add3L" id="plus3L-1" data-action="click->select-country-language#addLanguage3L">'
    htmlString += '<svg xmlns="http://www.w3.org/2000/svg" class="eqText-success" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor">'
    htmlString += '<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />'
    htmlString += '</svg></span></div></div></div>'
    parent.insertAdjacentHTML('beforeend', htmlString)
  }

  showOtherCountryFromModal() {
    let parent = document.querySelector('#showOtherCountry')
    parent.innerHTML = ''
    let htmlString = '<tr>'
    let div = this.getElement("addCountryDiv3L")
    let divs = div.querySelectorAll('.addParent')
    let trow = this.getElement("custom-trow")
    let count = 0
    for (let i = 1; i < divs.length + 1; i++) {
      let closestCountry = this.getElement("addCountry3L-" + i).value.trim()
      let closestLanguage = this.getElement("addLangauge3L-" + i).value.trim()
      if (closestCountry != "" && closestLanguage != "") {
        let cCC = this.capitalizeWords(closestCountry)
        let cCL = this.capitalizeWords(closestLanguage)
        if (this.setCountryValues(cCL + " (" + cCC + ")") == false) {
          count += 1
          htmlString += '<tr><td>' + `${i}` + '</td><td>' + `${closestLanguage} (${closestCountry})` + '</td><td><button type="button" class="btn btn-sm btn-link" data-toggle="modal" data-target="#otherModal">Edit</button></td></tr>'
        } else {
          let elem = document.querySelector('.ms-elem-selectable[data-value="' + `${cCL} (${cCC})` + '"]')
          elem.click()
        }
      }
    }
    htmlString += '</tr>'
    parent.insertAdjacentHTML('beforeend', htmlString)
    if (count > 0) {
      trow.classList.remove('d-none')
    } else {
      trow.classList.add('d-none')
    }
  }

  capitalizeWords(string) {
    const words = string.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    let cctWords = words.join(" ");
    return cctWords;
  }

  setCountryValues(value) {
    let lis = document.querySelectorAll(".ms-elem-selectable")
    let clArray = new Array()
    let clCheck = false;
    for (let i = 0; i < lis.length; i++) {
      if (lis[i]) {
        clArray.push(lis[i].dataset.value)
      }
    }
    if (clArray.indexOf(value) >= 0) {
      clCheck = true;
    }
    return clCheck;
  }

  addLanguage5L(e) {
    let get_id = e.currentTarget.id
    let result = get_id.split('-')
    let count = parseInt(result[1]) + 1
    let div = this.getElement("addCountryDiv5L")
    let closestCountryValue = this.getElement(get_id).closest(".addParent").querySelector(".addCountry5L").value.trim()
    let closestLanguageValue = this.getElement(get_id).closest(".addParent").querySelector(".addLangauge5L").value.trim()
    if (closestCountryValue != "" && closestLanguageValue != "") {
      let htmlString = '<div class="mb-3 row addParent" id="addedDiv5L' + count + '" data-number="' + count + '">'
      htmlString += '<label class="col-sm-4 col-md-4 col-form-label"></label>'
      htmlString += '<div class="row col-sm-8 col-md-8">'
      htmlString += '<div class="form-group col-sm-5 col-md-5">'
      htmlString += '<label><strong>Country/region</strong></label>'
      htmlString += '<input type="text" class="form-control addCountry5L no-paste" name="otherCountry5L[]">'
      htmlString += '</div>'
      htmlString += '<div class="form-group col-sm-5 col-md-5">'
      htmlString += '<label><strong>Language</strong></label>'
      htmlString += '<input type="text" class="form-control addLangauge5L no-paste" name="otherLanguage5L[]">'
      htmlString += '</div>'
      htmlString += '<div class="col-sm-2 col-md-2 pt-4">'
      htmlString += '<span class="remove5L" id="remove5L-' + count + '" data-action="click->select-country-language#remove5LDivAdded" ><i class="text-danger fas fa-minus-circle"></i></span>'
      htmlString += '<span class="add5L" id="plus5L-' + count + '" data-action="click->select-country-language#addLanguage5L"><i class="text-success fas fa-plus-circle"></i></span>'
      htmlString += '</div>'
      htmlString += '</div>'
      htmlString += '</div>'
      div.insertAdjacentHTML('beforeend', htmlString);
      this.preventPaste();
      const remove_btn = this.getElement('remove5L-' + parseInt(result[1]) + '')
      if (remove_btn) {
        this.hideElement(remove_btn.id)
      }
      this.hideElement(get_id)
      let insertOtherCountryLanguage = closestLanguageValue + " (" + closestCountryValue + ")"
      insertOtherCountryLanguage = this.getElement("otherCountryLanguage5L").value == '' ? insertOtherCountryLanguage : this.getElement("otherCountryLanguage5L").value += "\n" + insertOtherCountryLanguage
      this.getSelected("otherCountryLanguage5L", insertOtherCountryLanguage)
    } else {
      alert('Please input Other Country/region + Language')
    }
  }

  remove5LDivAdded(e) {
    this.removeDivLanguage(e, 'remove5L-', 'plus5L-', 'addedDiv5L')
  }

  addLanguageY(e) {
    let get_id = e.currentTarget.id
    let result = get_id.split('-')
    let count = parseInt(result[1]) + 1
    let div = this.getElement("addCountryDivY")
    let closestCountryValue = this.getElement(get_id).closest(".addParent").querySelector(".addCountryY").value.trim()
    let closestLanguageValue = this.getElement(get_id).closest(".addParent").querySelector(".addLangaugeY").value.trim()
    if (closestCountryValue != "" && closestLanguageValue != "") {
      let htmlString = '<div class="mb-3 row addParent" id="addedDivY' + count + '" data-number="' + count + '">'
      htmlString += '<label class="col-sm-4 col-md-4 col-form-label"></label>'
      htmlString += '<div class="row col-sm-8 col-md-8">'
      htmlString += '<div class="form-group col-sm-5 col-md-5">'
      htmlString += '<label><strong>Country/region</strong></label>'
      htmlString += '<input type="text" class="form-control addCountryY no-paste" name="otherCountryY[]">'
      htmlString += '</div>'
      htmlString += '<div class="form-group col-sm-5 col-md-5">'
      htmlString += '<label><strong>Language</strong></label>'
      htmlString += '<input type="text" class="form-control addLangaugeY no-paste" name="otherLanguageY[]">'
      htmlString += '</div>'
      htmlString += '<div class="col-sm-2 col-md-2 pt-4">'
      htmlString += '<span class="removeY" id="removeY-' + count + '" data-action="click->select-country-language#remove5LDivAdded" ><i class="text-danger fas fa-minus-circle"></i></span>'
      htmlString += '<span class="addY" id="plusY-' + count + '" data-action="click->select-country-language#addLanguageY"><i class="text-success fas fa-plus-circle"></i></span>'
      htmlString += '</div>'
      htmlString += '</div>'
      htmlString += '</div>'
      div.insertAdjacentHTML('beforeend', htmlString);
      this.preventPaste();
      const remove_btn = this.getElement('removeY-' + parseInt(result[1]) + '')
      if (remove_btn) {
        this.hideElement(remove_btn.id)
      }
      this.hideElement(get_id)
      let insertOtherCountryLanguage = closestLanguageValue + " (" + closestCountryValue + ")"
      insertOtherCountryLanguage = this.getElement("otherCountryLanguageY").value == '' ? insertOtherCountryLanguage : this.getElement("otherCountryLanguageY").value += "\n" + insertOtherCountryLanguage
      this.getSelected("otherCountryLanguageY", insertOtherCountryLanguage)
    } else {
      alert('Please input Other Country/region + Language')
    }
  }

  remove5LDivAdded(e) {
    this.removeDivLanguage(e, 'removeY-', 'plusY-', 'addedDivY')
  }

  selected_country_language3L(event) {
    this.selectingLanguage(event, "ul_selection3L_", "selection3L",
      "selected_country3L", "-selected3L-", "selected_language3l")
  }

  unselect_country_language3L(event) {
    this.unSelectingLanguage(event, "ul_selected_3L_", "selection3L",
      "selected_country3L", "-ms-selection3L-", "selected_language3l")
  }

  selected_country_language5L(event) {
    this.selectingLanguage(event, "ul_selection5L_", "selection5L",
      "selected_country5L", "-selected5L-", "selected_language5l")
  }

  unselect_country_language5L(event) {
    this.unSelectingLanguage(event, "ul_selected5L_", "selection5L",
      "selected_country5L", "-ms-selection5L-", "selected_language5l")
  }

  selected_country_languageY(event) {
    this.selectingLanguage(event, "ul_selectionY_", "selectionY",
      "selected_countryY", "-selectedY-", "selected_languageY")
  }

  unselect_country_languageY(event) {
    this.unSelectingLanguage(event, "ul_selected_Y_", "selectionY",
      "selected_countryY", "-ms-selectionY-", "selected_languageY")
  }

  clickNextMatrix(e) {
    let isValid = true
    isValid = this.validateFields(4)
    if (!isValid) {
      alert('Please select at least one language.')
      e.preventDefault()
    } else {
      this.executeNextTab("mytab-link-5", "mystep-5", "mytab-link-4")
      this.getSelected('current_tab', '5')
    }
  }

  clickPrev(e) {
    if (this.isChekedElement("Level_eq-5d-y-5l")) {
      this.executeNextTab("mytab-link-3-y-5l", "mystep-3", "mytab-link-4")
      this.getSelected('current_tab', '3.4')
    } else if (this.isChekedElement("Level_eq-5d-y-3l")) {
      this.executeNextTab("mytab-link-3-y", "mystep-3", "mytab-link-4")
      this.getSelected('current_tab', '3.3')
    } else if (this.isChekedElement("Level_eq-5d-5l")) {
      this.executeNextTab("mytab-link-3-5l", "mystep-3", "mytab-link-4")
      this.getSelected('current_tab', '3.2')
    } else if (this.isChekedElement("Level_eq-5d-3l")) {
      this.executeNextTab("mytab-link-3-3l", "mystep-3", "mytab-link-4")
      this.getSelected('current_tab', '3.1')
    } else {
      this.executeNextTab("mytab-link-3", "mystep-2", "mytab-link-4")
      this.getSelected('current_tab', '3')
    }
  }

  getMatrix() {
    const selected_language = [...document.querySelectorAll('.selected_language3l')].map(e => e.getAttribute('data-value').trim().split(/[()]/));
    let get_selected_language = this.getOtherCountryLanguage('.addCountry3L', '.addLangauge3L', selected_language)
    const mode_version3l = this.getCheckedValues('.mode3l:checked')
    const platform3l = this.getCheckedValues('.platform3l:checked')
    const device3l = this.getCheckedValues('.device3l:checked')
    const mode_version5l = this.getCheckedValues('.mode5l:checked')
    const platform5l = this.getCheckedValues('.platform5l:checked')
    const device5l = this.getCheckedValues('.device5l:checked')
    const mode_versiony = this.getCheckedValues('.modey:checked')
    const platformy = this.getCheckedValues('.platformy:checked')
    const devicey = this.getCheckedValues('.devicey:checked')
    const mode_versiony5l = this.getCheckedValues('.modey5l:checked')
    const platformy5l = this.getCheckedValues('.platformy5l:checked')
    const devicey5l = this.getCheckedValues('.devicey5l:checked')

    get_selected_language.forEach(function (item, index) {
      item.forEach(function (item2, index2) {
        let div = document.createElement("div");
        div.innerHTML = item2;
        let text = div.textContent || div.innerText || "";
        get_selected_language[index][index2] = text.replace(/\\n/g, '').replace(/\\t/g, '').trim();
      });
    });

    if (this.getElement("version3l").value == "yes") {
      this.getSelectedVersions(['EQ-5D-3L'], mode_version3l, platform3l, device3l, get_selected_language, 'eq-3l')
    }
    if (this.getElement("version5l").value == "yes") {
      this.getSelectedVersions(['EQ-5D-5L'], mode_version5l, platform5l, device5l, get_selected_language, 'eq-5l')
    }
    if (this.getElement("versiony").value == "yes") {
      this.getSelectedVersions(['EQ-5D-Y-3L'], mode_versiony, platformy, devicey, get_selected_language, 'eq-Y-3l')
    }
    if (this.getElement("versiony5l").value == "yes") {
      this.getSelectedVersions(['EQ-5D-Y-5L'], mode_versiony5l, platformy5l, devicey5l, get_selected_language, 'eq-Y-5l')
    }
  }

  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)
    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    this.getElement(currentTab).classList.remove('text-bold')

    find_tab.click()

  }

  preventPaste() {
    document.querySelectorAll(".no-paste").forEach(function (pasteBox, index, arr) {
      pasteBox.onpaste = e => {
        e.preventDefault();
        alert("Copy paste not allowed")
        return false;
      };
    })
  }
}
