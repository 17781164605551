import ApplicationController from './application_controller'

export default class extends ApplicationController {
  submitButtonSelector = 'input[type="submit"]#submit-form-button';
  errorMessageSelector = '#required-\\:is_agree';
  isAgreeSelector = '[name="research[is_agree]"]';
  hcaptchaSelector = '.h-captcha iframe';
  hcaptchaResponseSelector = '#hcaptcha-response';

  connect() {
    window.hcaptchaCallback = this.handleResponse.bind(this);
    this.element.addEventListener('keydown', this.preventEnterSubmit)
  }

  preventEnterSubmit(event) {
    if (event.key === 'Enter' && event.target.name !== 'search') {
      event.preventDefault();
    }
  }

  handleResponse(response) {
    let form = document.querySelector('form[data-controller="hcaptcha"]');
    let hiddenField = form.querySelector(this.hcaptchaResponseSelector);
  
    if (!hiddenField) {
      hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.id = 'hcaptcha-response';
      hiddenField.name = 'h_captcha_response';
      form.appendChild(hiddenField);
    }
  
    hiddenField.value = response;
  }

  submit(event) {
    let submitButton = event.target.querySelector(this.submitButtonSelector);
    let errorMessage = event.target.querySelector(this.errorMessageSelector);
    let attributes = ['disabled', 'data-disable-with'];

    if(!this.validateGenCondition(event)){
      event.preventDefault();
      errorMessage.classList.remove('d-none');
      
      attributes.forEach(attr => submitButton.removeAttribute(attr));
    } else if (!this.validateCaptcha()) {
      event.preventDefault();
      alert("Before you proceed to the registration of Experimental Version, please complete the hCaptcha below.");
  
      attributes.forEach(attr => submitButton.removeAttribute(attr));
    }else{
      submitButton.setAttribute('disabled', true);
      submitButton.setAttribute('data-disable-with', 'Submit');
    }

    errorMessage.classList.toggle('d-none', this.validateGenCondition(event));
  }

  validateCaptcha() {
    const hcatcha = document.querySelector(this.hcaptchaSelector);
    if (!hcatcha) return false;
  
    const hcatcha_response = hcatcha.getAttribute("data-hcaptcha-response");
    const hiddenField = document.querySelector(this.hcaptchaResponseSelector);
  
    return hcatcha_response !== "Invalid Data" && hcatcha_response !== "" && hcatcha_response === hiddenField.value;
  }

  validateGenCondition(event) {
    let is_agree = event.target.querySelectorAll(this.isAgreeSelector)[1];
    return is_agree.checked;
  }
}