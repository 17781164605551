import CrudController from './crud_controller'

export default class extends CrudController {

  update(event){
    event.currentTarget.checked ? this.active(event) : this.inActive(event)
  }

  active(event){
    let confirmation = confirm('Are you sure you want to active this product family')
    confirmation ? this.updateStatus(event, true) : event.currentTarget.checked = false
  }

  inActive(event){
    let confirmation = confirm('Are you sure you want to inactive this product family')
    confirmation ? this.updateStatus(event, false) : event.currentTarget.checked = true
  }

  updateStatus(event, status){
    const column_name = event.currentTarget.dataset["params"]
    event.currentTarget.checked = status
    if (column_name == "is_nc_active") {
      this.stimulate('ProductFamily#update_nc', event.currentTarget)

    }

    if (column_name == "is_c_active") {
      this.stimulate('ProductFamily#update_commercial', event.currentTarget)
    }
  }


}
