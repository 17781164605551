import FifthController from './fifth_controller'

export default class extends FifthController {
  static targets = ["tablink1", "tablink1p2", "tablink2", "tablink3", "tablink33l", "tablink35l", "tablink3y", "tablink3y5l", "tablink4", "tablink5", "tablink6", "tablink7",
  ]

  linkTab1(e) {
    let isValid = true
    isValid = this.validateFields(1)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1p2Target, this.tablink2Target, this.tablink3Target, this.tablink33lTarget,
        this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '1')
    }
  }

  linkTab1p2(e) {
    let isValid = true
    isValid = this.validateFields(1.2)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink2Target, this.tablink3Target, this.tablink33lTarget,
        this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '1.2')
    }
  }

  linkTab2(e) {
    let isValid = true
    isValid = this.validateFields(2)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink3Target, this.tablink33lTarget,
        this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '2')
    }
  }

  linkTab3(e) {
    let isValid = true
    isValid = this.validateFields(3)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink33lTarget,
        this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '3')
    }
  }

  linkTab33L(e) {
    this.hideModes3LPrev()
    let isValid = true
    isValid = this.validateFields(3.1)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.hideOrShowPlatform()
      this.tablink3Target.classList.add('text-bold')
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '3.1')
    }
  }

  linkTab35L(e) {
    this.hideModes5LPrev()
    let isValid = true
    isValid = this.validateFields(3.2)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink33lTarget, this.tablink3yTarget, this.tablink3y5lTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.hideOrShowPlatform()
      this.tablink3Target.classList.add('text-bold')
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '3.2')
    }
  }

  linkTab3Y(e) {
    this.hideModesYPrev()
    let isValid = true
    isValid = this.validateFields(3.3)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink33lTarget, this.tablink35lTarget, this.tablink3y5lTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.hideOrShowPlatform()
      this.tablink3Target.classList.add('text-bold')
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '3.3')
    }
  }

  linkTab3Y5L(e) {
    this.hideModesYPrev()
    let isValid = true
    isValid = this.validateFields(3.4)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink33lTarget, this.tablink35lTarget, this.tablink3yTarget, this.tablink4Target,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.hideOrShowPlatform()
      this.tablink3Target.classList.add('text-bold')
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '3.4')
    }
  }

  linkTab4(e) {
    let isValid = true
    isValid = this.validateFields(4)
    if (!isValid) {
      alert('Please select at least one language.')
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      this.hideElement('matrix-infobox')
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink33lTarget, this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget,
        this.tablink5Target, this.tablink6Target, this.tablink7Target
      )
      this.getElement("mytab-link-5").value = "next"
      this.getSelected('current_tab', '4')
    }
  }

  linkTab5(e) {
    let isValid = true
    isValid = this.validateFields(5)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      if (e.currentTarget.value == "next") {
        this.getMatrix()
      }
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink33lTarget, this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget,
        this.tablink4Target, this.tablink6Target, this.tablink7Target
      )
      this.getSelected('current_tab', '5')
    }
  }

  linkTab6(e) {
    let isValid = true
    isValid = this.validateFields(6)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink33lTarget, this.tablink35lTarget, this.tablink3yTarget,this.tablink3y5lTarget,
        this.tablink4Target, this.tablink5Target, this.tablink7Target
      )
      this.tablink5Target.value = "previous"
      this.getSelected('current_tab', '6')
    }
  }

  linkTab7(e) {
    let isValid = true
    isValid = this.validateFields(7)
    if (!isValid) {
      e.stopImmediatePropagation();
      e.preventDefault();
    } else {
      e.currentTarget.classList.add('text-bold')
      this.removeClass(this.tablink1Target, this.tablink1p2Target, this.tablink2Target, this.tablink3Target,
        this.tablink33lTarget, this.tablink35lTarget, this.tablink3yTarget, this.tablink3y5lTarget,
        this.tablink4Target, this.tablink5Target, this.tablink6Target
      )
      this.tablink5Target.value = "previous"
      this.getProducts()
      this.showSummary()
      this.setRouteDetials()
      this.setQualified()
      this.getSelected('current_tab', '7')
      this.getElement("00N1r00000HHVAU").value.trim() == "" ? this.getElement("mystep-7").classList.add('valid') : this.getElement("mystep-7").classList.remove('valid')
    }
  }

  removeClass(tabLink1, tabLink2, tabLink3, tabLink4, tabLink5, tabLink6, tabLink7, tabLink8, tabLink9, tabLink10, tabLink11) {
    tabLink1.classList.remove('text-bold')
    tabLink2.classList.remove('text-bold')
    tabLink3.classList.remove('text-bold')
    tabLink4.classList.remove('text-bold')
    tabLink5.classList.remove('text-bold')
    tabLink6.classList.remove('text-bold')
    tabLink7.classList.remove('text-bold')
    tabLink8.classList.remove('text-bold')
    tabLink9.classList.remove('text-bold')
    tabLink10.classList.remove('text-bold')
    tabLink11.classList.remove('text-bold')

    tabLink1.classList.remove('active')
    tabLink2.classList.remove('active')
    tabLink3.classList.remove('active')
    tabLink4.classList.remove('active')
    tabLink5.classList.remove('active')
    tabLink6.classList.remove('active')
    tabLink7.classList.remove('active')
    tabLink8.classList.remove('active')
    tabLink9.classList.remove('active')
    tabLink10.classList.remove('active')
    tabLink11.classList.remove('active')
  }

  selected_versions(tabLink3) {
    tabLink3.classList.add('text-bold')
  }

  hideModes3LPrev(e) {
    let div = document.querySelector('#eq5d3lcollapseOne')
    let span = document.querySelector('#span-show-modes-3L-show')
    let divs = div.querySelectorAll('.d-flex')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')

      for (let i = 2; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  hideModes5LPrev(e) {
    let div = document.querySelector('#eq5d5lcollapseOne')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-5L-show')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')

      for (let i = 2; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  hideModesYPrev(e) {
    let div = document.querySelector('#eq5dycollapseOne')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-Y-show')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')

      for (let i = 3; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }

  hideModesY5LPrev(e) {
    let div = document.querySelector('#eq5dy5lcollapseOne')
    let divs = div.querySelectorAll('.d-flex')
    let span = document.querySelector('#span-show-modes-Y-5L-show')

    if (span.classList.contains('d-none')) {
      span.classList.add('d-flex')
      span.classList.remove('d-none')

      for (let i = 3; i < divs.length; i++) {
        if (divs[i]) {
          divs[i].classList.add('d-none')
          divs[i].classList.remove('d-flex')
        }
      }
    }
  }
}
