import SelectingLanguageMethodController from './selecting_language_method_controller'

export default class extends SelectingLanguageMethodController {

  getSelectedVersions(selected_version, selected_mode, selected_platform, selected_device, selected_language, version_class){
    const div = selected_version[0]+ '-matrix'
    const level_table = document.getElementById(selected_version[0])
    const regtype = document.getElementById('regtype').value
    
    const selected_products = document.getElementById(div) 
    let url = '/api/v1/get_selected_products'

    const selected_data = {version: selected_version, mode: selected_mode, 
      platform: selected_platform, device: selected_device, language: selected_language, regtype: regtype}
    
    // const create_table = this.createTable(selected_language, displayHeaderVersion, selectedVersionValue, selected_version)  
    if (level_table !== null) {
      level_table.remove()
    }

    this.fetchProducts(url, selected_data) 
      // .then(data => console.log(data))
                      .then(data => selected_products.innerHTML += this.createTable(selected_version, data, version_class))
  }

  getVersionValue(version, id){
    let hidden = document.getElementById(id)
    hidden.value == "" ? hidden.value = version : hidden.value = hidden.value + '\n' + version
   }

   getAvailableProducts(array){
    return array.map(arr => arr[1]).join('\n')
  }

  mergeArray(value){
    return value.map(v => v[1])
  }

  initializeValue(id){
    let initialize_value = document.getElementById(id).value
    initialize_value === "" ? initialize_value = id : initialize_value += id
  }

  checkNewValues(id, check_value){
    let initialize_value = document.getElementById(id)
    return initialize_value.value == "" ? initialize_value.value = check_value : initialize_value.value += check_value
  }

  resetValue(id){
    document.getElementById(id).value = ""
  }
    
  createTable(selected_version, data, version_class){
    const selected_level = selected_version[0]
    this.resetValue('requested-unavail-version-'+ selected_level)
    this.resetValue('unrequested-unavail-version-'+ selected_level)
    // If API is created
    const selectedAvailableProducts = Object.entries(data.selected_products)
    let availableProducts = Object.entries(data.available_products)
    let productId = Object.entries(data.available_versions)
    let products_header = Object.entries(data.products_header)
    let byod_content = Object.entries(data.byod)
    let country_language = Object.entries(data.exist_country_language)
    let other_language = Object.entries(data.other_language)

    const ExistProductsArray = this.separateProducts(selectedAvailableProducts)
    const available_ids = this.mergeArray(productId)
    const available_versions = this.mergeArray(selectedAvailableProducts)
    availableProducts = this.mergeArray(availableProducts)
    products_header = this.mergeArray(products_header)
    country_language = this.mergeArray(country_language)
    other_language = this.mergeArray(other_language)
    const col_span = availableProducts.length + 1 //to remove?
    byod_content = this.mergeArray(byod_content)
    let byod_details = ''
    
    if (byod_content[0] !== undefined) {
      byod_details = byod_content[0]['body']
    }
    productId = this.getAvailableProducts(productId)
    let availableProductsArr = this.getAvailableProducts(selectedAvailableProducts)
    if (this.getElement("regtype").value == '2') {
      let udValue = this.getElement("unsupportedDigital").value
      this.getElement("unsupportedDigital").value = availableProductsArr.split('\n').some(a => a.includes('Digital')) ?  '1' : udValue
		  this.checkUnavailable()
      this.checkFastTrack()
    }
    return this.newProductsTable(available_versions, available_ids, productId, ExistProductsArray, selected_level, 
      country_language, availableProducts, products_header, byod_details, version_class, other_language, availableProductsArr)
  }

  newProductsTable(available_versions, available_ids, product_id, exist_products, selected_version, selected_language, availableProducts, productsHeader, byod_details, version_class, other_language, availableProductsArr){
    let alert_class = ''
    let containsBYOD = productsHeader.find(p => p.includes("BYOD"))
    byod_details = (typeof containsBYOD !== 'undefined') ? byod_details : ''
    alert_class = byod_details != '' ? 'alert alert-info' : ''
    let products_table = ` <div id="${selected_version}" class= >
                                <input type="hidden" id="product-id-${selected_version}" class="product_ids" value="${product_id}">
                                <input type="hidden" id="requested-version-${selected_version}" class="requested_versions" value="${availableProductsArr}">
                                <input type="hidden" id="unrequested-version-${selected_version}" class="unrequested_versions" value="">
                                <input type="hidden" id="available-ids-${selected_version}" class="available_id" value="${available_ids}">
                                <input type="hidden" id="available-versions-${selected_version}" class="available_version" value="${available_versions}">
                                <table class="table table-border w-100">
                                  <thead>
                                  <th width="20%" class="borderRemove"><b class="py-2 h5 eq-5l ${version_class}">${selected_version}</b></th>
                                    ${this.createVersionHeader(productsHeader, byod_details)}
                                  </thead>
                                  <tbody>
                                    ${this.createTrTable(selected_version, selected_language, exist_products, availableProducts, other_language)}
                                  </tbody>
                                </table>
                                <div class="col-md-12 col-sm-12 mb-5 ${alert_class}">
                                  ${byod_details}
                                </div>
                              </div> `
    this.showElement('matrix-infobox')
    if (availableProducts.length == 0) {
      this.hideElement('matrix-infobox')
      products_table = this.blankProducts(selected_version)
    }
    return products_table
  }

  blankProducts(selected_version){
    let no_available_products_table = ` <div id="${selected_version}" class="col-sm-8 col-md-10 col-lg-12">
      <table class="table "> 
        <thead>
          <th colspan="12" class="text-center infoBoxOrangeMatrix" id="no-product-${selected_version}">
          The combination of criteria you selected in the previous steps under  “SELECT EQ-5D VERSION(S)” unfortunately results in no available EQ-5D products. Please go back to adjust your selection or contact us at <a href='mailto:userinformationservice@euroqol.org'>userinformationservice@euroqol.org</a>
          </th>
        </thead>
      </table>
    </div> `
    return no_available_products_table

  }

  getTotalLength(array){
    return array.length
  }

  createVersionHeader(displayHeader, byod_details){
    let extractHeader = ``
    if (displayHeader.length != 0) {
      let find_largest_header = this.findLargestLength(displayHeader)
      extractHeader = displayHeader.map(dh => `<th class="thColumn p-0">${this.modifyVersionHeader(dh, byod_details, find_largest_header)}</th>`).join('')
    }
    
    return extractHeader
  }

  findLargestLength(headers) {
    let find_largest =  headers.sort(function(a, b) {return b.split('#').length - a.split('#').length})[0]
    find_largest = find_largest.split('#')
    return find_largest.length
  }

  modifyVersionHeader(header, byod_details, find_largest_header){
    let version_header = header.split('#')
    return this.modifyEachHeader(version_header, byod_details, find_largest_header)
  }

  modifyEachHeader(headers, byod_details, find_largest_header){
    let matrix_header = headers.map((header, index) => {
      return byod_details === "" ?  this.headerWithoutBYOD(headers, header, index, find_largest_header)
        : this.modifyHeaderbyVersion(headers, header, index)
    }
    ).join('')

    return matrix_header
  }

  modifyHeaderbyVersion(headers, header, index){
    let get_header = ``
    if (headers.length === 4) {
      get_header =  index === 0 ? `<i class="font-weight-bold px-4">${header}</i><br>`: 
                    header === "BYOD" ? `<i class="font-weight-bold text-info px-4">${header}</i> ` : `<i class="font-weight-light px-4">${header}</i><br>`; 
    } 
    else if(headers.length === 2){
      get_header =  index === 0 ? `<i class="font-weight-bold px-4">${header}</i><br>`: `<i class="font-weight-light px-4">${header}</i><br><br><br>`;
    }
    else {
      get_header =  index === 0 ? `<i class="font-weight-bold px-4">${header}</i><br>`:  
                    index === 1 ? `<i class="font-weight-light px-4">${header}</i><br>`:
                    `<i class="font-weight-light px-4">${header}</i><br><br>`; 
    }

    return get_header
  }
  
  headerWithoutBYOD(headers ,header, index, find_largest_header){
    return find_largest_header === 3 ? this.headerThreeColumn(headers, header, index)
      : this.headerTwoColumn(header, index)
  }

  headerThreeColumn(headers, header, index){
    let get_header = ``
    if(headers.length === 2){
      get_header =  index === 0 ? `<i class="font-weight-bold px-4">${header}</i><br>`: `<i class="font-weight-light px-4">${header}</i><br><br>`;
    }
    else {
      get_header =  index === 0 ? `<i class="font-weight-bold px-4">${header}</i><br>`:  
                    index === 1 ? `<i class="font-weight-light px-4">${header}</i><br>`:
                    `<i class="font-weight-light px-4">${header}</i>`; 
    }
    return get_header
  }

  headerTwoColumn(header, index){
    return index === 0 ? `<i class="font-weight-bold px-4">${header}</i><br>`: `<i class="font-weight-light px-4">${header}</i>`;

  }

  getNewlyRequested(country, language){
    const other_country = this.getByClassName(country)
    const other_language = this.getByClassName(language)
    let merge_country_language = [] 
    other_country.map((country, index) => merge_country_language.push([other_language[index][0], country[0]]))
    
    return merge_country_language

  }

  groupByCountry(arr, country, selected_version, existProducts, availableProducts){
    let display_country_language = arr.map(col => {
      if (col[1] === country) {
        return `<tr> 
          <td class="tdColumn">${col[0].trim()} (${col[1]})</td>
          ${this.createCheckbox(selected_version, col[0].trim(), col[1], existProducts, availableProducts, 0)}
       <tr>`
      }
    }).join('')
    return display_country_language
  }

  // TH for other languages
  groupByOtherLanguage(arr, selected_version, existProducts, availableProducts, country_lang_length, length, index){
    let header = []
    const last_index = country_lang_length - 1
    const colspan = length + 1
    if (arr.length != 0 && last_index === index) {
      header = arr.length > 1 ? ["Newly Requested Languages"] : ["Newly Requested Language"]
      header = header.map(col =>
      `<tr>  <td class="tdColumn font-weight-bold" colspan="${colspan}">${col}</td></tr>
      ${this.otherLanguages(arr, selected_version, existProducts, availableProducts)}` 
     )
    }

    return header.join('')
  }

  // TD for other languages
  otherLanguages(arr, selected_version, existProducts, availableProducts,){
    let display_array = arr.map(col => 
      ` <tr> 
        <td class="tdColumn">${col[1]} (${col[0].trim()})</td>
          ${this.createCheckbox(selected_version,col[1], col[0].trim(), existProducts, availableProducts, 1)}
        <tr>`
   ).join('')

   return display_array
  }


  createTrTable(selected_version, country_language, existProducts, availableProducts, other_language){
    let remove_blankstring = country_language
    // let remove_other_language = other_language
    const get_country = this.getCountry(country_language)
    const col_span_country = availableProducts.length + 1
    let display_array = []
    if (get_country.length > 0) {
      display_array = get_country.map((country, index) =>
        `<tr> 
        <td class="tdColumn font-weight-bold" colspan="${col_span_country}">${country}</td> 
        ${this.groupByCountry(remove_blankstring, country, selected_version, existProducts, availableProducts)}
        ${this.groupByOtherLanguage(other_language, selected_version, existProducts, availableProducts, get_country.length, availableProducts.length, index)}
        </tr>`
      ).join('')
    } else {
      display_array = `<tr> 
        ${this.groupByOtherLanguage(other_language, selected_version, existProducts, availableProducts, 1, 1, 0)}
        </tr>` 
    }
  
    return display_array
  }

  getCountry(country_language){
    let countries = country_language.map(country => country[1])
    countries = [...new Set(countries)]
    return countries
  
  }

  createCheckbox(selected_version, language, country, existProducts, availableProducts, check_language){
    let extractCheckbox = availableProducts.map(header => 
      this.checkExistProducts(selected_version, country, language, header, existProducts, check_language)
    ).join('')

    return extractCheckbox
  }

  checkExistProducts(selected_version, country, language, header, existProducts, check_language){
    let productName = `${language} (${country}) | ${header}`
    if (check_language === 1) {
      productName = `${language} (${country})# | ${header}`
    }
    const findProducts = existProducts.find(pd => pd === productName )
    // const findProducts = existProducts.find(pd => pd.includes(productName) )
    let checkExistProducts = findProducts ? this.AvailableVersion(selected_version, productName) : this.UnavailableVersion(selected_version, productName)

    return checkExistProducts
  }

  AvailableVersion(level, product_name){
    let Available = `<td class="available tdColumn">
    <div class="form-check roundBoxMatrix">
      <input type="checkbox" name="${level}" data-action="click->selected-version#clickCheckAvailableVersion" class="availabe_version" id="${product_name}" value="${product_name}" checked>
      <label class="form-check-label text-left" for="${product_name}">
        <small>Available</small>
      </label>
      </div>
      </td>`

    return Available
  }

  UnavailableVersion(level, product_name){
    const id = 'unrequested-unavail-version-' + level
    this.getVersionValue(product_name, id)
    let availableProduct = `
      <td class="tdColumn unavailable">
      <div class="form-check roundBoxMatrix">
        <input type="checkbox" name="${level}" class="unavailable_version" data-action="click->selected-version#clickCheckUnavailableVersion" id="${product_name}" value="${product_name}">
        <label class="form-check-label text-left" for="${product_name}">
          <small>Unavailable</small>
        </label>
      </div>
      </td>`
    return availableProduct
  }

  removeEmptyString(countryLanguage){
    const removeEmpty = countryLanguage.map(cLang => cLang.filter(item => item ))
    return removeEmpty
  }

  separateProducts(products_array){
    const get_country = products_array.map(product => product[1])
    return get_country
  }

  async fetchProducts(url = '', data = {}) {    
    const response = await fetch(url, {
      method: 'POST', 
      headers: {
        // 'X-CSRF-Token': token,
        'credentials': "same-origin",
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', 
      body: JSON.stringify(data) 
    })
    return response.json() 
  }



}