import CrudController from './crud_controller'

export default class extends CrudController {
  static targets = [ "id" ]

  createMode(event) {
    this.create(event, 'Mode#create')
  }

  getId(event){
    document.getElementById('mode-id').value = event.currentTarget.id
  }

  deleteMode(event){
    this.destroy(event, 'Mode#destroy', this.id)
  }

  editMode(event){
    this.edit(event, 'Mode#edit')
  }

  updateMode(event){
    this.update(event, 'Mode#update')
  }

  reset(event){
    event.preventDefault()
    Turbolinks.visit(location.href)
  }

  get id() {
    return this.idTarget.value
  }
  
}
