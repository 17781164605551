import ApplicationController from './application_controller'

export default class extends ApplicationController {

  create(event, reflex ){
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.stimulate(reflex, form)  
  }

  edit(event, reflex){
    event.preventDefault();
    this.stimulate(reflex, event.currentTarget.id)
  }

  update(event, reflex){
    event.preventDefault()
    this.stimulate(reflex, event.currentTarget)
  }

  destroy(event, reflex, id){
    event.preventDefault()
    this.stimulate(reflex, id)
  }

  destroySuccess(){
    const url = `${window.location.pathname}`;
    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  updateSuccess(){
    Turbolinks.visit(location.href);
  }

  reset(event){
    event.preventDefault()
    Turbolinks.visit(location.href)
  }

  createSuccess(){
    Turbolinks.visit(location.href)
  }

  createError(){
    alert("Error");
  }


}

