import { Controller } from "stimulus"

export default class extends Controller {
  findPosition(target) {
    let currenttop = 0;
    if (target.offsetParent) {
      do {
        currenttop += target.offsetTop;
      } while ((target = target.offsetParent));
      return [currenttop];
    }
  }

  findPositionInt(target) {
    let currenttop = 0;
    if (target.offsetParent) {
      do {
        currenttop += target.offsetTop;
      } while ((target = target.offsetParent));
      return currenttop;
    }
  }

  scroll2Content(pos) {
    window.scroll(0, pos, "smooth")
  }

  checkEmail(fieldTarget, formatTarget) {
    let isValid = true
    if (!this.emailIsValid(fieldTarget.value)) {
      fieldTarget.focus()
      formatTarget.classList.remove("d-none")
      isValid = false
    } else {
      formatTarget.classList.add("d-none")
    }
    return isValid
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  checkBlank(valid, fieldTarget, errorTarget) {
    let isValid = valid
    if (fieldTarget.value.trim() == '') {
      fieldTarget.focus()
      errorTarget.classList.remove("d-none")
      isValid = false
    } else {
      errorTarget.classList.add("d-none")
    }
    return isValid
  }

  checkDisabledBlank(valid, fieldTarget, errorTarget) {
    let isValid = valid
    if (!fieldTarget.disabled && fieldTarget.value == '') {
      fieldTarget.focus()
      errorTarget.classList.remove("d-none")
      isValid = false
    } else {
      errorTarget.classList.add("d-none")
    }
    return isValid
  }

  checkRadioBlank(valid, fieldTarget1, fieldTarget2, errorTarget) {
    let isValid = valid
    if (!this.isChekedElement(fieldTarget1) && !this.isChekedElement(fieldTarget2)) {
      fieldTarget1.focus()
      errorTarget.classList.remove("d-none")
      isValid = false
    } else {
      errorTarget.classList.add("d-none")
    }
    return isValid
  }

  checkStartDateIsGreater(valid, fieldTarget1, fieldTarget2, errorTarget) {
    let isValid = valid
    if (new Date(fieldTarget1.value).getTime() > new Date(fieldTarget2.value).getTime()) {
      fieldTarget2.focus()
      errorTarget.classList.remove("d-none")
      isValid = false
    } else {
      errorTarget.classList.add("d-none")
    }
    return isValid
  }

  isChekedElement(target) {
    let isChecked = (target.checked) ? true : false
    return isChecked
  }

  computeDuration(start_date_pickrTarget, end_date_pickrTarget, durationTarget) {
    let startTimestamp = 0
    let endTimestamp = 0
    endTimestamp = new Date(end_date_pickrTarget.value).getTime()
    if (this.start_date_pickrTarget.value.trim() != "") {
      startTimestamp = new Date(start_date_pickrTarget.value).getTime()
      if (startTimestamp > endTimestamp) {
        start_date_pickrTarget.focus()
        alert('“End date must be equal to or after Start date.”')
      } else if (startTimestamp == endTimestamp) {
        this.durationTarget.value = 0
      } else {
        this.durationTarget.value = this.dateDiffInYears(new Date(start_date_pickrTarget.value), new Date(end_date_pickrTarget.value))
      }
    } else {
      start_date_pickrTarget.focus()
      alert('“End date must be equal to or after Start date.”')
    }
  }

  getDateFormat(dateValue) {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let newDate = new Date(dateValue)
    let newDay = (`0${newDate.getDate()}`).slice(-2)
    let newMonth = months[newDate.getMonth()]
    let newYear = newDate.getFullYear()

    return `${newDay}-${newMonth}-${newYear}`
  }

  dateDiffInYears(dateold, datenew) {
    let ynew = datenew.getFullYear()
    let mnew = datenew.getMonth()
    let dnew = datenew.getDate() - 1
    let yold = dateold.getFullYear()
    let mold = dateold.getMonth()
    let dold = dateold.getDate()
    let diff = ynew - yold
    if (mold > mnew) diff--
    else {
      if (mold == mnew) {
        if (dold > dnew) diff--
      }
    }
    return diff
  }

  mot_countWords(eTarget1, eTarget2, eTarget3) {
    let text = eTarget1.value;
    text = text.trim();
    let words = text.split(/\s+/);
    words = words.filter(function (word) {
      return word.length > 0;
    });
    if (words.length > 300) {
      text = words.slice(0, 300).join(' ');
      eTarget1.value = text;
      eTarget2.innerText = 300;
      eTarget3.classList.remove('d-none')
    } else {
      eTarget2.innerText = words.length
      eTarget3.classList.add('d-none')
    }
  }

  sum_countWords(eTarget1, eTarget2, eTarget3) {
    let text = eTarget1.value;
    text = text.trim();
    let words = text.split(/\s+/);
    words = words.filter(function (word) {
      return word.length > 0;
    });
    if (words.length > 700) {
      text = words.slice(0, 700).join(' ');
      eTarget1.value = text;
      eTarget2.innerText = 700;
      eTarget3.classList.remove('d-none')
    } else {
      eTarget2.innerText = words.length
      eTarget3.classList.add('d-none')
    }
  }

  inst_cont_countWords(eTarget1, eTarget2, eTarget3) {
    let text = eTarget1.value;
    text = text.trim();
    let words = text.split(/\s+/);
    words = words.filter(function (word) {
      return word.length > 0;
    });
    if (words.length > 700) {
      text = words.slice(0, 700).join(' ');
      eTarget1.value = text;
      eTarget2.innerText = 700;
      eTarget3.classList.remove('d-none')
    } else {
      eTarget2.innerText = words.length
      eTarget3.classList.add('d-none')
    }
  }

  getProductsAndIds(targetChildren, targetIds, targetNames) {
    let productIds = ''
    let products = ''
    for (let i = 0; i < targetChildren.length; i++) {
      productIds += targetChildren[i].getAttribute('data-productid') + "\n"
      products += targetChildren[i].getAttribute('data-productname') + "\n"
    }
    targetIds.value = productIds.slice(0, -2)
    targetNames.value = products.slice(0, -2)
  }

  verifyEmail(data, emailTarget, prevEmailTarget, check, x, question, load, loadtext) {
    if (data.deliverable == true) {
      load.classList.add("d-none")
      loadtext.classList.add("d-none")
      check.classList.remove("d-none")
    } else if (data.deliverable == false) {
      load.classList.add("d-none")
      loadtext.classList.add("d-none")
      x.classList.remove("d-none")
      alert("Can you please check the spelling of your email address? The email checker was not able to verify your email address. This could mean that we cannot reach you. Please press the blue Ok button on the screen below to return to the page to enter your email address.")
    } else {
      load.classList.add("d-none")
      loadtext.classList.add("d-none")
      question.classList.remove("d-none")
      alert("The email checker was not able to check if you entered a valid email address. Please double check the spelling of your email address. If your email address is spelled incorrectly we cannot reach you. Please press the orange Back button on the screen below to return to the page ‘Your details’ to check the spelling of your email address")
    }
    prevEmailTarget.value = emailTarget.value
  }

  async getEmail(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', // include, *same-origin, omit
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }
}