import CrudController from './crud_controller'

export default class extends CrudController {
  static targets = [ "id" ]


  createDevice(event) {
    this.create(event, 'Device#create')
  }

  getId(event){
    document.getElementById('device-id').value = event.currentTarget.id
  }

  deleteDevice(event){
    this.destroy(event, 'Device#destroy', this.id)
  }

  editDevice(event){
    this.edit(event, 'Device#edit')
  }

  updateDevice(event){
    this.update(event, 'Device#update')
  }

  get id() {
    return this.idTarget.value
  }
}
