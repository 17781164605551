import CrudController from './crud_controller'

export default class extends CrudController {
  static targets = [ "id" ]

  getId(event){
    document.getElementById('platform-id').value = event.currentTarget.id
  }

  deletePlatform(event){
    this.destroy(event, 'Platform#destroy', this.id)
  }

  editPlatform(event){
    this.edit(event, 'Platform#edit')
  }

  updatePlatform(event){
    this.update(event, 'Platform#update')
  }

  reset(event){
    event.preventDefault()
    Turbolinks.visit(location.href)
  }

  get id() {
    return this.idTarget.value
  }

  // createSuccess(){
  //   Turbolinks.visit(location.href);
  // }

}
