import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["input"];

  validateInput(event) {
    const input = event.target;
    let userInput = input.value.trim();
    const westernRegex = /[\w\s\d!"#$%&'()*+,\-.\/:;<=>?@\[\\\]^_`{|}~]+$/;
    if (userInput.length > 0 && !westernRegex.test(userInput)) {
      userInput = userInput.replace(/[^\w\s\d!"#$%&'()*+,\-.\/:;<=>?@\[\\\]^_`{|}~]/g, '');
      input.value = userInput;
      alert('Only Latin script characters are allowed.');
      event.preventDefault();
    }
  }
}
