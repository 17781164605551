import MethodController from './method_controller'

export default class extends MethodController {

  clickNext(e) {
    this.updateVendor()
    let isValid = true
    isValid = this.validateFields(2)
    if (isValid) {
      this.executeNextTab("mytab-link-3", "mystep-3", "mytab-link-2")
      this.getSelected('current_tab', '3')
    } else {
      e.preventDefault()
    }

  }

  clickPrev(e) {
    this.executeNextTab("mytab-link-1p2", "mystep-1p2", "mytab-link-2")
    this.getSelected('current_tab', '1.2')
  }

  changeDescription(e) {
    let description = this.getElement("00N1r00000HHVAb")
    let summaryDescription = this.getElement("summary-description")
    summaryDescription.innerHTML = description.value
  }

  changeObj(e) {
    let objective = this.getElement("00N1r00000HHVAH")
    let summaryObj = this.getElement("summary-objective")
    summaryObj.innerHTML = objective.value
  }

  changeSponsor(e) {
    let sponsor = this.getElement("00N1r00000HHVAW")
    let summarySponsor = this.getElement("summary-sponsor")
    summarySponsor.innerHTML = sponsor.value
  }

  changeCro(e) {
    let cro = this.getElement("00N1r00000HHVAC")
    let summaryCro = this.getElement("summary-cro")
    let summaryCroDiv = this.getElement("summary-cro-div")
    if (cro.value.trim() != '') {
      summaryCroDiv.classList.remove('d-none')
      summaryCroDiv.classList.add('d-flex')
    } else {
      summaryCroDiv.classList.add('d-none')
      summaryCroDiv.classList.remove('d-flex')
    }
    summaryCro.innerHTML = cro.value
  }

  changeVendor(e) {
    let vendor = this.getElement("00N1r00000HHVAD")
    let summaryVendor = this.getElement("summary-vendor")
    let summaryVendorDiv = this.getElement("summary-vendor-div")
    if (vendor.value.trim() != '') {
      summaryVendorDiv.classList.remove('d-none')
      summaryVendorDiv.classList.add('d-flex')
    } else {
      summaryVendorDiv.classList.add('d-none')
      summaryVendorDiv.classList.remove('d-flex')
    }
    summaryVendor.innerHTML = vendor.value
  }

  changeEq5dUsed(e) {
    let eq5dUsed = this.getElement("00N1r00000HHVA8")
    let eq5dUsedText = this.getElement("00N1r00000HHVAI")
    let eq5dUsedTextDiv = this.getElement("other-00N1r00000HHVA8")
    let summaryEq5dUsed = this.getElement("summary-eq5dused")
    let summaryEq5dUsedOtherDiv = this.getElement("summary-eq5dusedother-div")

    summaryEq5dUsed.innerHTML = eq5dUsed.value
    if (eq5dUsed.value == 'Other') {
      eq5dUsedText.disabled = false
      eq5dUsedTextDiv.classList.remove('d-none')
      eq5dUsedTextDiv.classList.add('d-flex')
      summaryEq5dUsedOtherDiv.classList.remove('d-none')
      summaryEq5dUsedOtherDiv.classList.add('d-flex')
    } else {
      eq5dUsedText.disabled = true
      eq5dUsedTextDiv.classList.remove('d-flex')
      eq5dUsedTextDiv.classList.add('d-none')
      summaryEq5dUsedOtherDiv.classList.add('d-none')
      summaryEq5dUsedOtherDiv.classList.remove('d-flex')
    }
  }

  changeEq5dUsedOther(e) {
    let eq5dUsedOther = this.getElement("00N1r00000HHVAI")
    let summaryEq5dUsedOther = this.getElement("summary-eq5dusedother")
    summaryEq5dUsedOther.innerHTML = eq5dUsedOther.value
  }

  changeStudy(e) {
    let study = this.getElement("00N1r00000HHVA4")
    let studyText = this.getElement("00N1r00000HHVAK")
    let studyTextDiv = this.getElement("other-00N1r00000HHVA4")
    let patient = this.getElement("00N1r00000HHVAG")
    let np_limit = this.getElement("np_limit")
    let noPatients = this.getElement("noPatients")
    let summaryStudy = this.getElement("summary-study")
    let summaryStudyOtherDiv = this.getElement("summary-studyother-div")

    summaryStudy.innerHTML = study.value
    if (study.value == 'Other') {
      studyText.disabled = false
      studyTextDiv.classList.add('d-flex')
      studyTextDiv.classList.remove('d-none')
      summaryStudyOtherDiv.classList.remove('d-none')
      summaryStudyOtherDiv.classList.add('d-flex')
    } else {
      summaryStudyOtherDiv.classList.add('d-none')
      summaryStudyOtherDiv.classList.remove('d-flex')
      if (study.value == "Routine data collection" || study.value == "PROMs - Alberta" || study.value == "PROMs - CIHI" || study.value == "Swedish National Quality Registries") {
        if (study.value == 'PROMs - Alberta') {
          alert('For users from Alberta-Canada, please contact APERSU www.apersu.ca');
        }
        np_limit.value = 100000
      } else {
        np_limit.value = 10000
      }
      if (patient.value != "") {
        noPatients.value = patient.value
      }
      studyText.disabled = true
      studyTextDiv.classList.remove('d-flex')
      studyTextDiv.classList.add('d-none')
    }
    this.checkFastTrack()
  }

  changeStudyOther(e) {
    let studyOther = this.getElement("00N1r00000HHVAK")
    let summaryEq5dUsedOther = this.getElement("summary-studyother")
    summaryEq5dUsedOther.innerHTML = studyOther.value
  }

  changeClinic(e) {
    let clinic = this.getElement("00N1r00000HHVA1")
    let clinicText = this.getElement("00N1r00000HHVAJ")
    let clinicTextDiv = this.getElement("other-00N1r00000HHVA1")
    let summaryClinic = this.getElement("summary-clinic")
    let summaryClinicOtherDiv = this.getElement("summary-clinicother-div")

    summaryClinic.innerHTML = clinic.value
    if (clinic.value == 'Other / Unknown') {
      clinicText.disabled = false
      clinicTextDiv.classList.add('d-flex')
      clinicTextDiv.classList.remove('d-none')
      summaryClinicOtherDiv.classList.remove('d-none')
      summaryClinicOtherDiv.classList.add('d-flex')
    } else {
      clinicText.disabled = true
      clinicTextDiv.classList.remove('d-flex')
      clinicTextDiv.classList.add('d-none')
      summaryClinicOtherDiv.classList.add('d-none')
      summaryClinicOtherDiv.classList.remove('d-flex')
    }
  }

  changeClinicOther(e) {
    let clinicOther = this.getElement("00N1r00000HHVAJ")
    let summaryClinicOther = this.getElement("summary-clinicother")
    summaryClinicOther.innerHTML = clinicOther.value
  }

  changePatient(e) {
    let study = this.getElement("00N1r00000HHVA4")
    let patient = this.getElement("00N1r00000HHVAG")
    let np_limit = this.getElement("np_limit")
    let noPatients = this.getElement("noPatients")
    let summaryPatients = this.getElement("summary-patients")

    summaryPatients.innerHTML = patient.value
    if (study.value == "Routine data collection" || study.value == "PROMs - Alberta" || study.value == "PROMs - CIHI" || study.value == "Swedish National Quality Registries") {
      np_limit.value = 100000
    } else {
      np_limit.value = 10000
    }
    if (patient.value != "") {
      noPatients.value = patient.value
    }
    this.checkFastTrack()
  }

  clickPlan(e) {
    let value = e.target.value
    let planText = this.getElement("00N1r00000KXPSg")
    let planTextDiv = this.getElement("other-00N1r00000KXPSh")
    let summaryApp = this.getElement("summary-app")
    let summaryAppTextDiv = this.getElement("summary-apptext-div")

    if (value == '1') {
      planText.disabled = false
      planTextDiv.classList.add('d-flex')
      planTextDiv.classList.remove('d-none')
      summaryApp.innerHTML = 'Yes'
      summaryAppTextDiv.classList.remove('d-none')
      summaryAppTextDiv.classList.add('d-flex')
      this.getSelected("useinapp_qualify", "n")
    } else {
      planText.disabled = true
      planTextDiv.classList.remove('d-flex')
      planTextDiv.classList.add('d-none')
      summaryApp.innerHTML = 'No'
      summaryAppTextDiv.classList.add('d-none')
      summaryAppTextDiv.classList.remove('d-flex')
      this.getSelected("useinapp_qualify", "y")
    }
  }

  addVendor(e) {
    let value = e.target.value
    let summaryVendorInvolved = this.getElement("summary-vendors-involved")
    let summaryVendorDiv = this.getElement("summary-vendors-div")
    let addDivVendor = this.getElement("addVendorParent")
    if (value == '1') {
      addDivVendor.classList.remove('d-none')
      summaryVendorInvolved.innerHTML = 'Yes'
      summaryVendorDiv.classList.remove('d-none')
      summaryVendorDiv.classList.add('d-flex')
    } else {
      addDivVendor.classList.add('d-none')
      summaryVendorInvolved.innerHTML = 'No'
      summaryVendorDiv.classList.add('d-none')
      summaryVendorDiv.classList.remove('d-flex')
    }
  }

  changePlanText(e) {
    let planText = this.getElement("00N1r00000KXPSg")
    let summaryPlanText = this.getElement("summary-apptext")
    summaryPlanText.innerHTML = planText.value
  }

  changeDate(e) {
    let startDate = this.getElement("00N1r00000HHVAX-pickr")
    let endDate = this.getElement("00N1r00000HHVAA-pickr")
    let startDateSF = this.getElement("00N1r00000HHVAX")
    let endDateSF = this.getElement("00N1r00000HHVAA")
    // let studyDuration = this.getElement("studyDuration")
    let regType = this.getElement("regtype")
    let summaryStartDate = this.getElement("summary-startdate")
    let summaryEndDate = this.getElement("summary-enddate")
    let startYear = 0
    let endYear = 0
    let duration = -1
    let startTimestamp = 0
    let endTimestamp = 0

    if (endDate.value.trim() != "") {
      endTimestamp = new Date(endDate.value).getTime()
    }

    if (startDate.value.trim() != "") {
      startTimestamp = new Date(startDate.value).getTime()
    }


    if (startDate.value.trim() == "" || startTimestamp > endTimestamp) {
      if (endTimestamp != 0) {
        alert('“End date must be equal to or after Start date.”')
        this.showElement('required-00N1r00000HHVAA')
        this.getElement('required-00N1r00000HHVAA').innerHTML = "<small>End date must be equal to or after Start date.</small>"
        startDate.focus()
      }
    } else {
      this.hideElement('required-00N1r00000HHVAA')
    }

    let startD = new Date(startDate.value)
    let endD = new Date(endDate.value)
    if (startTimestamp != 0 && endTimestamp != 0) {
      if (startTimestamp == endTimestamp) {
        duration = 0
      } else {
        duration = this.dateDiffInYears(startD, endD)
      }
    }

    if (regType.value == '2') {
      if (duration >= 10) {
        alert('“Maximum term for which EQ-5D registration can be arranged is 10 years. You are requested to re-register after this term.”')
        this.showElement('required-00N1r00000HHVAA')
        this.getElement('required-00N1r00000HHVAA').innerHTML = "<small>Maximum term for which EQ-5D registration can be arranged is 10 years. You are requested to re-register after this term.</small>"
      }
    }

    let formattedStartDate = this.getDateFormat(startDate.value)
    let formattedEndDate = this.getDateFormat(endDate.value)

    studyDuration.value = duration
    summaryStartDate.innerHTML = formattedStartDate
    summaryEndDate.innerHTML = formattedEndDate
    startDateSF.value = formattedStartDate
    endDateSF.value = formattedEndDate
    this.checkFastTrack()
  }

  getDateFormat(dateValue) {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let newDate = new Date(dateValue)
    let newDay = (`0${newDate.getDate()}`).slice(-2)
    let newMonth = months[newDate.getMonth()]
    let newYear = newDate.getFullYear()

    return `${newDay}-${newMonth}-${newYear}`
  }

  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)
    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    this.getElement(currentTab).classList.remove('text-bold')

    find_tab.click()
  }

  addVendorDiv(e) {
    let get_id = e.currentTarget.id
    let result = get_id.split('-')
    let count = parseInt(result[1]) + 1
    let div = this.getElement("addVendor")

    let closestVendorName = this.getElement(get_id).closest(".addVendorParent").querySelector(".addVendorEmail").value.trim()
    let closestVendorEmail = this.getElement(get_id).closest(".addVendorParent").querySelector(".addVendorPhone").value.trim()
    if (this.emailIsValid(closestVendorEmail) && closestVendorName != "") {
      let htmlString = '<div class="addVendorParent" id="addedDivVendor' + count + '"  data-number="' + count + '">'
      htmlString += '<div class="row vendor-content col-sm-12 col-md-12 ml-3">'
      htmlString += '<div class="form-group pl-0 pr-1 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addVendorName-' + count + '" class="form-control mt-1 addVendorEmail" name="vendorName[]">'
      htmlString += '</div>'
      htmlString += '<div class="form-group pl-1 pr-0 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addVendorEmail-' + count + '" class="form-control mt-1 addVendorPhone" name="vendorEmail[]">'
      htmlString += '</div>'
      htmlString += '<div class="col-sm-2 col-md-2 px-0">'
      htmlString += '<span class="removeVendor" id="removeVendor-' + count + '" data-action="click->second#removeVendorDiv"><svg xmlns="http://www.w3.org/2000/svg" class="text-danger" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '<span class="addVendor mr-1" id="plusVendor-' + count + '" data-action="click->second#addVendorDiv"><svg xmlns="http://www.w3.org/2000/svg" class="eqText-success" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '</div>'
      htmlString += '</div>'
      htmlString += '</div>'
      div.insertAdjacentHTML('beforeend', htmlString);
      const remove_btn = this.getElement('removeVendor-' + parseInt(result[1]) + '')
      if (remove_btn) {
        this.hideElement(remove_btn.id)
      }
      this.hideElement(get_id)
    } else {
      alert('Please Input Correct Email/Vendor or CRO')
    }
  }

  removeVendorDiv(e) {
    this.removeDivVendor(e, 'removeVendor-', 'plusVendor-', 'addedDivVendor')
  }

  removeDivVendor(e, removeId, addId, addDivId) {
    let get_id = e.currentTarget.id
    const get_count = get_id.split('-')
    let result = parseInt(get_count[1]) - 1
    const remove_div = this.getElement('' + addDivId + get_count[1] + '')
    const remove_btn = this.getElement('' + removeId + '' + result + '')
    if (remove_btn) {
      this.showElement(remove_btn.id)
    }
    this.showElement('' + addId + result + '')
    remove_div.remove()
  }

  updateVendor() {
    let textVendor = this.getElement("00NQu000002dihe")
    let sumTextVendors = this.getElement("summary-vendors")
    let textVendorValue = ''
    let divs = document.querySelectorAll('.addVendorParent')
    for (let i = 0; i < divs.length; i++) {
      let closestVendorEmail = this.getElement("addVendorEmail-" + i).value.trim()
      let closestVendorName = this.getElement("addVendorName-" + i).value.trim()
      if (this.emailIsValid(closestVendorEmail) && closestVendorName != "") {
        textVendorValue += `${closestVendorName} <${closestVendorEmail}>\n`
      }
    }
    textVendor.value = textVendorValue.trim()
    sumTextVendors.innerText = textVendorValue.trim()
  }
}
