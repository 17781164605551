import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@hotwired/turbo-rails"

if (window.location.href.includes('/research-and-development') ) {
  window.Turbo = Turbo
} else {
  Turbolinks.start()
}

Rails.start()
ActiveStorage.start()
require("trix")
require("@rails/actiontext")

import "controllers";
import 'bootstrap/dist/css/bootstrap';
import 'bootstrap/dist/js/bootstrap';
import './custom';
import "@fortawesome/fontawesome-free/js/all"