import SelectCountryLanguageController from './select_country_language_controller'

export default class extends SelectCountryLanguageController {

  clickNext(e){
    this.getProducts()
    let isValid = true
    isValid = this.validateFields(5)
    if (!isValid) {
      alert('Please check at least 1 EQ-5D Version')
      e.preventDefault()
    } else {
      this.executeNextTab("mytab-link-6", "mystep-6", "mytab-link-5")
      this.getSelected('current_tab', '6')
    }
  }

  clickPrev(e) {
    this.executeNextTab("mytab-link-4", "mystep-3", "mytab-link-5")
    this.getSelected('current_tab', '4')
  }

  getProducts(){
    const requested_versions = this.getValueBySplit('requested_versions')
    const product_ids = this.getValueByCLass('product_ids')
    const unrequested_versions = this.getValueByCLass('unrequested_versions')
    const requested_unavail_versions = this.getValueByCLass('requested-unavail-version')
    const unrequested_unavail_versions = this.getValueByCLass('unrequested-unavail-version')

    this.insertValue('00N1r00000HHVAU', requested_unavail_versions)
    this.insertValue('uuv-matrix', unrequested_unavail_versions)
    this.insertValue('uav-matrix', unrequested_versions)
    this.insertValue('00N1r00000KWbjp', product_ids)    
    this.insertValue('00N1r00000HHVAT', requested_versions)
  }
  
  getValueBySplit(className){
    let remove_comma = [...document.getElementsByClassName(className)].map(e => e.value.split(',').join('\n'))
    return remove_comma.join('\n')
  }

  getValueByCLass(className){
    let remove_comma = [...document.getElementsByClassName(className)].map(e => e.value.split('\n').join('\n'))
    return remove_comma.join('\n')
  }

  insertValue(id, value){
    document.getElementById(id).value = value.replace(',','')
  }

  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)
    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    this.getElement(currentTab).classList.remove('text-bold')

    find_tab.click()
  }
}
