import MethodController from './method_controller'

export default class extends MethodController {
  
  submitForm(e) {
    let tab1 = this.getElement("valid_tab1").value
    let tab2 = this.getElement("valid_tab2").value
    let tab3 = this.getElement("valid_tab3").value
    let tab4 = this.getElement("valid_tab4").value
    let tab5 = this.getElement("valid_tab5").value
    let tab6 = this.getElement("valid_tab6").value
    let hcatcha = document.getElementsByClassName('h-captcha')[0]
    let hcatcha_response = hcatcha.querySelector("iframe").getAttribute("data-hcaptcha-response")

    if (tab1 == "yes" && tab2 == "yes" && tab3 == "yes" && tab4 == "yes" && tab5 == "yes" && tab6 == "yes") {
      if (this.getElement("00N1r00000HHVAU").value.trim() != "") {
        if (this.getElement("00N1r00000HHVA3").value.trim() == "") {
          alert("Please complete the Desired date of availability")
          e.preventDefault()
        }
        if (hcatcha_response == "Invalid Data" || hcatcha_response == "") {
          alert("Please complete the captcha")
          e.preventDefault()
        }
      } else {
        if (hcatcha_response == "Invalid Data" || hcatcha_response == "") {
          alert("Please complete the captcha")
          e.preventDefault()
        }
      }
    } else {
      e.preventDefault()
    }
  }
}